import {Action} from '@ngrx/store';
import {AdminRootActions, AdminRootLoadedAction, SaveUsignalErrorAction, USignaleLoadedAction} from './admin.actions';
import {AdminRootResource, UnprocessableEntityError, UnterscheidungssignalListResource} from './admin.model';
import {createEmptyStateResource, createStateResource, StateResource} from '@schir-int-client/ngrx-helpers';
import {ROUTER_NAVIGATED} from '@ngrx/router-store';

export interface AdminState {
	admin: AdminRootResource | null;
	unterscheidungssignale: StateResource<UnterscheidungssignalListResource>;
	usignalError: UnprocessableEntityError;
}

export const initialState: AdminState = {
	admin: null,
	unterscheidungssignale: createEmptyStateResource(),
	usignalError: null,
};

export function adminReducer(state: AdminState = initialState, action: Action): AdminState {
	switch (action.type) {
		case AdminRootActions.ADMIN_ROOT_LOADED:
			return {
				...state,
				admin: (<AdminRootLoadedAction>action).payload,
			};
		case AdminRootActions.USIGNALE_LOADED:
			return {
				...state,
				unterscheidungssignale: createStateResource((<USignaleLoadedAction>action).unterscheidungssignale),
			};
		case AdminRootActions.SAVE_ERROR:
			return {
				...state,
				usignalError: sortIssues((<SaveUsignalErrorAction>action).error),
			};
		case AdminRootActions.CLEAR_ERROR:
			return {
				...state,
				usignalError: null,
			};
		case ROUTER_NAVIGATED:
			return {
				...state,
				unterscheidungssignale: {
					...state.unterscheidungssignale, reload: true,
				},
			};
		default:
			return state;
	}

	function sortIssues(error: UnprocessableEntityError) {
		error.error.issues.sort((a, b) => a.field.localeCompare(b.field));

		return error;
	}
}
