import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {AppState, getApiRoot} from '@schir-int-client/api-root';
import {of} from 'rxjs';
import {catchError, filter, map, mergeMap, switchMap, withLatestFrom} from 'rxjs/operators';
import {
	AdminRootActions,
	AdminRootLoadedAction,
	AskToDeleteUnterscheidungssignalAction,
	ClearErrorAction,
	DeleteUnterscheidungssignalAction,
	SaveUsignalErrorAction,
	UploadUSignaleAction,
	USignaleLoadedAction,
} from './admin.actions';
import {AdminFacade} from './admin.facade';
import {
	AdminRootResource,
	ErrorType,
	UnterscheidungssignalListResource,
	UnterscheidungssignalResource,
} from './admin.model';
import {AdminService} from './admin.service';
import {isNil} from 'lodash-es';
import {DoNothingAction} from '@schir-int-client/ngrx-helpers';
import {AdminMessages} from './admin.messages';
import {DialogDataYesNo} from '@schir-int-client/dialog-shared';
import {DialogService} from 'libs/tech/src/lib/dialog.service';

@Injectable()
export class AdminEffects {
	constructor(
		private actions: Actions,
		private adminRootService: AdminService,
		private dialogService: DialogService,
		private facade: AdminFacade,
		private store: Store<AppState>,
	) { }


	loadAdminRoot = createEffect(() => this.actions.pipe(
		ofType(AdminRootActions.LOAD_ADMIN_ROOT),
		withLatestFrom(this.store.select(getApiRoot())),
		switchMap(([, apiRoot]) => {
			return this.adminRootService.getAdminRoot(apiRoot).pipe(
				map((adminRoot: AdminRootResource) => {
					return new AdminRootLoadedAction(adminRoot);
				}),
			);
		}),
	));


	loadUnterscheidungssignale = createEffect(() => this.actions.pipe(
		ofType(AdminRootActions.LOAD_USIGNALE),
		withLatestFrom(this.facade.adminRoot),
		filter(([, adminRoot]) => !isNil(adminRoot)),
		switchMap(([, adminRoot]) => {
			return this.adminRootService.getUnterscheidungssignale(adminRoot).pipe(
				map((usignale: UnterscheidungssignalListResource) => {
					return new USignaleLoadedAction(usignale);
				}),
			);
		}),
	));


	uploadUnterscheidungssignale = createEffect(() => this.actions.pipe(
		ofType(AdminRootActions.UPLOAD_USIGNALE),
		withLatestFrom(this.facade.adminRoot),
		switchMap(([action, adminRoot]) => {
			return this.adminRootService.uploadUnterscheidungssignale(adminRoot, (<UploadUSignaleAction>action).file).pipe(
				catchError((error: any) => of(new SaveUsignalErrorAction({
					error: error.error,
					type: ErrorType.UNPROCESSABLE_ENTITY,
				}))),
				mergeMap(usignale => {
					if (usignale instanceof SaveUsignalErrorAction) {
						return [usignale];
					}
					return [new USignaleLoadedAction(usignale), new ClearErrorAction()];
				}),
			);
		}),
	));


	confirmDelete = createEffect(() => this.actions.pipe(
		ofType(AdminRootActions.ASKTO_DELETE_USIGNAL),
		switchMap((action) => {
			const usignal: UnterscheidungssignalResource = (<AskToDeleteUnterscheidungssignalAction>action).usignal;

			return this.dialogService.openDialogYesNo(AdminMessages.ASK_TO_DELETE_USIGNAL.replace('{usignal}', usignal.signal)).pipe(
				map((dialog: DialogDataYesNo) => {
					if (dialog && dialog.agree) {
						return new DeleteUnterscheidungssignalAction(usignal);
					}

					return new DoNothingAction();
				}),
			);
		}),
	));


	delete = createEffect(() => this.actions.pipe(
		ofType(AdminRootActions.DELETE_USIGNAL),
		switchMap((action) => {
			const usignal: UnterscheidungssignalResource = (<AskToDeleteUnterscheidungssignalAction>action).usignal;

			return this.adminRootService.delete(usignal).pipe(map(usignale => new USignaleLoadedAction(usignale)));
		}),
	));

}


