import {Component, Input, OnChanges} from '@angular/core';
import {KontaktKategorie, KontaktKategorieLabel} from '@schir-int-client/adressverwaltung-shared';
import {
	AufgabenTyp,
	AufgabeResource,
	AufgabeVersandBody,
	AufgabeVersandTaetigkeitLabel,
} from '@schir-int-client/aufgabe-shared';
import {isEmpty, isNil} from 'lodash-es';

@Component({
	selector: 'schir-int-client-aufgabe-versand-body',
	templateUrl: './aufgabe-versand-body.component.html',
	styleUrls: ['./aufgabe-versand-body.component.scss'],
})
export class AufgabeVersandBodyComponent implements OnChanges {

	@Input() aufgabe: AufgabeResource;

	readonly versandTaetigkeitLabel = AufgabeVersandTaetigkeitLabel;
	readonly aufgabenTyp = AufgabenTyp;

	private empfaengerList: KontaktKategorie[] = [];

	constructor() { }

	ngOnChanges(): void {
		const list = [...this.body.empfaenger];
		let keys = Object.keys(KontaktKategorie);
		list.sort((a, b) => keys.indexOf(a) - keys.indexOf(b));
		this.empfaengerList = list;
	}

	get body(): AufgabeVersandBody {
		return <AufgabeVersandBody>this.aufgabe.body;
	}

	get empfaenger(): string {
		return this.empfaengerList.map(kategorie => KontaktKategorieLabel[kategorie]).join(', ');
	}

	get hasFaxEmpfaenger(): boolean {
		if (!isNil(this.aufgabe)) {
			return 'faxEmpfaenger' in this.body && !isEmpty(this.body.faxEmpfaenger);
		}
	}

	get faxEmpfaenger(): string {
		if (isNil(this.body.faxEmpfaenger)) {
			return '';
		}
		return this.body.faxEmpfaenger.map(kategorie => KontaktKategorieLabel[kategorie]).join(', ');
	}
}
