<div dir="rtl" class="menu-button">
	<button mat-icon-button [matMenuTriggerFor]="menu" data-test-id="more-button" #menuTrigger='matMenuTrigger' matTooltip="Weitere Funktionen"
	        tabindex="0" aria-haspopup="menu" aria-label="Weitere Funktionen" class="focus-box-shadow-light">
		<mat-icon>more_vert</mat-icon>
	</button>
	<mat-menu #menu="matMenu" class="menu">
		<button class="menu-item focus-box-shadow-dark" mat-menu-item (click)="openNotizDialog($event)" data-test-id="notiz-button"
				aria-label="Notiz bearbeiten" matTooltip="Notiz bearbeiten">
			<mat-icon>notes</mat-icon>
			<span id="bt-notiz">Notiz</span>
		</button>
		<button class="menu-item" mat-menu-item *ngIf="hasRegisterBlatt()" (click)="openRegisterBlatt()"
			data-test-id="registerblatt-button" aria-label="Registerblatt anzeigen"  matTooltip="Registerblatt anzeigen">
			<mat-icon>table_chart</mat-icon>
			<span id="bt-registerblatt">Registerblatt</span>
		</button>
		<button class="menu-item focus-box-shadow-dark" mat-menu-item *ngIf="verfahren | hasResourceLink: linkRel.REGISTERBLATT_SCHIFFSZERTIFIKAT" (click)="downloadSchiffszertifikat()"
			data-test-id="schiffszertifikat-button" aria-label="Schiffszertifikat erstellen und herunterladen"
			matTooltip="Schiffszertifikat erstellen und herunterladen">
			<i class="icon-award" aria-hidden="true"></i>
			<span id="bt-schiffszertifikat">Schiffszertifikat</span>
		</button>
		<button class="menu-item focus-box-shadow-dark" mat-menu-item *ngIf="verfahren | hasResourceLink: linkRel.REGISTERBLATT_SCHIFFSBRIEF" (click)="downloadSchiffsbrief()"
			data-test-id="schiffsbrief-button" aria-label="Schiffsbrief erstellen und herunterladen"
				matTooltip="Schiffsbrief erstellen und herunterladen">
			<i class="icon-award" aria-hidden="true"></i>
			<span id="bt-schiffsbrief">Schiffsbrief</span>
		</button>
		<button class="menu-item focus-box-shadow-dark" mat-menu-item *ngIf="verfahren | hasResourceLink: linkRel.REGISTERBLATT_AMTLICHER_AUSZUG" (click)="openAmtlichenAuszugErstellenDialog()"
			data-test-id="amtlicher-auszug-button" aria-label="Amtlichen Auszug erstellen und herunterladen"
				matTooltip="Amtlichen Auszug erstellen und herunterladen">
			<i class="icon-bundesadler" aria-hidden="true"></i>
			<span id="bt-amtlicher-auszug">Amtlicher Auszug</span>
		</button>
		<button class="menu-item focus-box-shadow-dark" mat-menu-item *ngIf="verfahren | hasResourceLink: linkRel.MARK_AS_ABGELEHNT" (click)="markAsAbgelehnt($event)"
			data-test-id="ablehnen-button" aria-labelledby="bt-verfahren-ablehnen">
			<mat-icon>pan_tool</mat-icon>
			<span id="bt-verfahren-ablehnen">Verfahren ablehnen</span>
		</button>
		<button class="menu-item focus-box-shadow-dark" mat-menu-item *ngIf="(verfahren | hasResourceLink: linkRel.ASSIGN_USIGNAL) && verfahren.register === 'SSR'" (click)="openUsignalZuordnenDialog()"
				data-test-id="add-usignal-button" aria-labelledby="bt-usignal-zuordnen">
			<mat-icon>notification_important</mat-icon>
			<span id="bt-usignal-zuordnen">U-Signal zuordnen</span>
		</button>
		<button class="menu-item focus-box-shadow-dark" mat-menu-item *ngIf="(verfahren | hasResourceLink: linkRel.STANDORT_AKTE)" (click)="openStandortAkteDialog()"
				data-test-id="standort-akte-button" aria-labelledby="bt-standort-akte">
			<mat-icon>edit_location</mat-icon>
			<span id="bt-standort-akte">Standort der Akte</span>
		</button>
		<button class="menu-item focus-box-shadow-dark" mat-menu-item *ngIf="(verfahren | hasResourceLink: linkRel.AR_KENNZEICHEN)" (click)="openKennzeichenDialog()"
				data-test-id="kennzeichen-button" aria-labelledby="bt-kennzeichen">
			<mat-icon>fingerprint</mat-icon>
			<span id="bt-kennzeichen">Kennzeichen</span>
		</button>
		<button class="menu-item focus-box-shadow-dark" mat-menu-item *ngIf="(verfahren | hasResourceLink: linkRel.ZAEHLBLATT)" (click)="openZaehlblattErfassenDialog()"
				data-test-id="zaehlblatt-erfassen-button" aria-labelledby="bt-zaehlblatt-erfassen">
			<mat-icon>playlist_add</mat-icon>
			<span id="bt-zaehlblatt_erfassen">Zählblattaktion erfassen</span>
		</button>
		<button class="menu-item focus-box-shadow-dark" mat-menu-item *ngIf="(verfahren | hasResourceLink: linkRel.ZAEHLBLATT)" (click)="openZaehlblattAnzeigenDialog()"
				data-test-id="zaehlblatt-anzeigen-button" aria-labelledby="bt-zaehlblatt-anzeigen">
			<mat-icon>playlist_add_check</mat-icon>
			<span id="bt-zaehlblatt-anzeigen">Zählblattaktion anzeigen</span>
		</button>
	</mat-menu>
</div>
