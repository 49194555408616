import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {ResourceUri} from '@ngxp/rest';
import {doIfLoadingRequired, StateResource} from '@schir-int-client/ngrx-helpers';
import {RegisterName} from '@schir-int-client/register-shared';
import {Observable, Subject} from 'rxjs';
import {filter, first} from 'rxjs/operators';
import {
	AskToDeleteVerfahrenAction,
	AskToMarkAsAbgelehntAction,
	AskToUnassignKontaktAction,
	AssignUSignalAction,
	CreateNeueintragungAskToAction,
	CreateVerfahrenWithoutPosteingangAction,
	DownloadAmtlicherAuszugAction,
	DownloadAusdruckAction,
	DownloadSchiffsbriefAction,
	DownloadSchiffszertifikatAction,
	ExportVerfahrenAction,
	LoadVerfahrenAction,
	LoadVerfahrenSingleAction,
	LoadVerfahrenSingleSuccessAction,
	SetVerfahrenPropertyAction,
	UnassignKontaktAction,
	UpdateVerfahrenNotizAction,
	VerfahrenBearbeitenAction,
	VerfahrenBearbeitenBeendenAction,
	VerfahrenSearchAction,
	VerfahrenUebernehmenAction,
} from './verfahren.actions';
import {
	NewVerfahren,
	RegisterAusdruckType,
	VerfahrenListResource,
	VerfahrenResource,
	VerfahrenSearchQuery,
} from './verfahren.model';
import {
	blattNummerByVerfahrenSelector,
	isAmtlicherAuszugDoneSelector,
	isVerfahrenSelectedSelector,
	selectedVerfahrenSelector,
	verfahrenListAsArraySelector,
	verfahrenSearchBySelector,
	verfahrenSelector,
	verfahrenSingleSelector,
} from './verfahren.selectors';
import {VerfahrenRootState} from './verfahren.state';
import {KontaktWithKategorie} from '@schir-int-client/adressverwaltung-shared';

@Injectable({ providedIn: 'root' })
export class VerfahrenFacade {

	readonly verfahrenCreatedSubject = new Subject();

	constructor(private store: Store<VerfahrenRootState>) { }

	blattNummer: Observable<number> = this.store.select(blattNummerByVerfahrenSelector);

	verfahrenSingle: Observable<VerfahrenResource> = this.store.select(verfahrenSingleSelector);

	verfahren: Observable<StateResource<VerfahrenListResource>> = this.store.select(verfahrenSelector).pipe(
		filter(verfahrenStateResource => !doIfLoadingRequired(verfahrenStateResource, () => this.store.dispatch(new LoadVerfahrenAction()))),
	);

	verfahrenResultList = this.store.select(verfahrenListAsArraySelector);

	selectedVerfahren: Observable<VerfahrenResource> = this.store.select(selectedVerfahrenSelector);
	verfahrenSearchBy: Observable<VerfahrenSearchQuery> = this.store.select(verfahrenSearchBySelector);

	isVerfahrenSelected(verfahrenResource: VerfahrenResource): Observable<boolean> {
		return this.store.select(isVerfahrenSelectedSelector, { verfahren: verfahrenResource });
	}

	setSelectedVerfahren(verfahrenResource: VerfahrenResource): void {
		this.selectedVerfahren.pipe(first()).subscribe(predecessor => {
			this.store.dispatch(new LoadVerfahrenSingleSuccessAction(verfahrenResource, predecessor));
		});
	}

	loadSingleVerfahren(verfahrenSingleUri: string) {
		this.store.dispatch(new LoadVerfahrenSingleAction(verfahrenSingleUri));
	}

	searchVerfahren(searchQuery: VerfahrenSearchQuery, limit?: number): void {
		if (limit) {
			searchQuery = { ...searchQuery, limit: limit };
		}

		this.store.dispatch(new VerfahrenSearchAction(searchQuery));
	}

	downloadAusdruck(type: RegisterAusdruckType): void {
		this.store.dispatch(new DownloadAusdruckAction(type));
	}

	downloadAmtlicherAuszug(): Observable<StateResource<boolean>> {
		this.store.dispatch(new DownloadAmtlicherAuszugAction());

		return this.store.select(isAmtlicherAuszugDoneSelector);
	}

	downloadSchiffszertifikat(): void {
		this.store.dispatch(new DownloadSchiffszertifikatAction());
	}

	downloadSchiffsbrief(): void {
		this.store.dispatch(new DownloadSchiffsbriefAction());
	}

	exportVerfahren(url: string): void {
		this.store.dispatch(new ExportVerfahrenAction(url));
	}

	updateNotiz(notiz: string, verfahrenUri: ResourceUri): void {
		this.store.dispatch(new UpdateVerfahrenNotizAction(notiz, verfahrenUri));
	}

	assignUSignal(verfahren: VerfahrenResource): void {
		this.store.dispatch(new AssignUSignalAction(verfahren));
	}

	createVerfahren(newVerfahren: NewVerfahren): void {
		this.store.dispatch(new CreateVerfahrenWithoutPosteingangAction(newVerfahren));
	}

	createNeueintragung(register: RegisterName): void {
		this.store.dispatch(new CreateNeueintragungAskToAction(register));
	}

	bearbeiten(): void {
		this.store.dispatch(new VerfahrenBearbeitenAction());
	}

	bearbeitenBeenden(): void {
		this.store.dispatch(new VerfahrenBearbeitenBeendenAction());
	}

	uebernehmen(): void {
		this.store.dispatch(new VerfahrenUebernehmenAction());
	}

	markAsAbgelehnt(verfahren: VerfahrenResource): void {
		this.store.dispatch(new AskToMarkAsAbgelehntAction(verfahren));
	}

	delete(verfahren: VerfahrenResource): void {
		this.store.dispatch(new AskToDeleteVerfahrenAction(verfahren));
	}

	isAmtlicherAuszugDone(): Observable<StateResource<boolean>> {
		return this.store.select(isAmtlicherAuszugDoneSelector);
	}

	unassignKontakt(kontaktWithKategorie: KontaktWithKategorie) {
		let check = true;
		this.selectedVerfahren.forEach(verfahren => check = check && verfahren.verfahrenKontakte.length > 1);
		check ? this.store.dispatch(new UnassignKontaktAction(kontaktWithKategorie.kontakt, kontaktWithKategorie.kategorie))
			: this.store.dispatch(new AskToUnassignKontaktAction(kontaktWithKategorie.kontakt, kontaktWithKategorie.kategorie));
	}

	setVerfahrenProperty(verfahren: VerfahrenResource, linkRel: string, value: any) {
		this.store.dispatch(new SetVerfahrenPropertyAction(verfahren, linkRel, value));
	}
}

export const noLimit = 10000;
