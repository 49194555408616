<div *ngIf="apiRoot | async as _apiRoot" class="c-areas" data-test-id="dashboard">
	<schir-int-client-posteingang-list class="c-areas__area" *ngIf="(_apiRoot | hasResourceLink: 'posteingangs') && _apiRoot.ekpConfig.enabled">
	</schir-int-client-posteingang-list>

	<schir-int-client-ruecklauf class="c-areas__area" [headline]="isLFPR ? 'Geschäftsstelle' : 'Rücklauf'"
		*ngIf="(_apiRoot | hasResourceLink: 'vorgaengeInRuecklauf') && (vorgaengeInRuecklauf | async) as _vorgaenge" [vorgaenge]='_vorgaenge' [aktenStandorte]="aktenStandorte">
	</schir-int-client-ruecklauf>

	<schir-int-client-eingang-list class="c-areas__area" [headline]="isLFPR ? 'Rechtspflege' : 'Eingänge'" [icon]="'email'"
		*ngIf="(_apiRoot | hasResourceLink: 'vorgaengeFuerRechtspfleger') && (vorgaengeForRechtspflege | async) as _vorgaenge"
								   [vorgaenge]="_vorgaenge" [aktenStandorte]="aktenStandorte">
	</schir-int-client-eingang-list>

	<schir-int-client-wiedervorlage-list class="c-areas__area"
		*ngIf="(_apiRoot | hasResourceLink: ApiRootLinkRel.VORGAENGE_IN_WIEDERVORLAGE)">
	</schir-int-client-wiedervorlage-list>
</div>
