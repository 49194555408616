import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {getUrl, hasLink} from '@ngxp/rest';
import {UserFacade, UserResource} from '@schir-int-client/user-shared';
import {
	VorgangActions,
	VorgangFacade,
	VorgangLinkRel,
	VorgangResource,
	VorgangStatusLabel,
	VorgangTypLabel,
	VorgangTypLabelLFPR,
	VorgangTypLabelSchiff,
} from '@schir-int-client/vorgang-shared';
import {Observable, of, Subscription} from 'rxjs';
import {ChangeVorgangStatusDialogComponent} from '@schir-int-client/vorgang';
import {ofType} from '@ngrx/effects';
import {ActionsSubject} from '@ngrx/store';
import {DialogService, HandlesBackdropClickAndEscapeKey} from '@schir-int-client/tech';
import {ApiRootFacade} from '@schir-int-client/api-root';

@Component({
	selector: 'schir-int-client-eingang-list',
	templateUrl: './eingang-list.component.html',
	styleUrls: ['./eingang-list.component.scss'],
})
export class EingangListComponent extends HandlesBackdropClickAndEscapeKey<ChangeVorgangStatusDialogComponent>
	implements OnInit, OnDestroy {

	@Input() vorgaenge: VorgangResource[];
	@Input() aktenStandorte: Map<string, string>;
	@Input() headline: string;
	@Input() icon: string;

	vorgangStatusLabel = VorgangStatusLabel;
	vorgangsTypen: VorgangTypLabel;

	private subscriptions: Subscription[] = [];

	constructor(private userFacade: UserFacade,
	            private router: Router,
	            private vorgangFacade: VorgangFacade,
	            protected dialogService: DialogService,
	            private actionListener: ActionsSubject,
	            private apiRootFacade: ApiRootFacade) {
		super(dialogService);
	}

	ngOnInit() {
		this.subscriptions.push(this.actionListener.pipe(
			ofType(VorgangActions.MARKED_SUCCESS)).subscribe(_value => {
				this.vorgangFacade.loadVorgaengeRechtspfleger();
			},
		));

		this.subscriptions.push(this.apiRootFacade.getCurrentApiRoot().subscribe(apiRoot =>
			this.vorgangsTypen = apiRoot.features.lfpr ? VorgangTypLabelLFPR : VorgangTypLabelSchiff,
		));
	}

	ngOnDestroy() {
		this.subscriptions.forEach(s => s.unsubscribe());
	}

	openStatusDialog(vorgang: VorgangResource) {
		this.matDialogRef = this.dialogService.openEditorDialog(this, ChangeVorgangStatusDialogComponent, {
			data: {
				vorgang: vorgang,
			},
		});
	}

	getRechtspfleger(vorgang: VorgangResource): Observable<UserResource> {
		return hasLink(vorgang, VorgangLinkRel.RECHTSPFLEGER) ? this.userFacade.getUserByUri(vorgang, VorgangLinkRel.RECHTSPFLEGER) : of(null);
	}

	goToVorgang(vorgang: VorgangResource) {
		this.vorgangFacade.setSelectedVorgang(vorgang);
		this.router.navigate(['/verfahren', btoa(getUrl(vorgang, VorgangLinkRel.VERFAHREN)), {
			outlets: {
				'vorgang': btoa(getUrl(vorgang)),
			},
		}]);
	}

	getStandOrtAkteForVorgang(vorgang: VorgangResource) {
		return this.aktenStandorte.get(vorgang._links.self.href);
	}
}
