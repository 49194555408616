import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {getUrl} from '@ngxp/rest';
import {
	VorgangActions,
	VorgangFacade,
	VorgangLinkRel,
	VorgangResource,
	VorgangStatusLabel,
	VorgangTypLabel,
	VorgangTypLabelLFPR,
	VorgangTypLabelSchiff,
} from '@schir-int-client/vorgang-shared';
import {ofType} from '@ngrx/effects';
import {ChangeVorgangStatusDialogComponent} from '@schir-int-client/vorgang';
import {ActionsSubject} from '@ngrx/store';
import {Subscription} from 'rxjs';
import {DialogService, HandlesBackdropClickAndEscapeKey} from '@schir-int-client/tech';
import {ApiRootFacade} from '@schir-int-client/api-root';

@Component({
	selector: 'schir-int-client-ruecklauf',
	templateUrl: './ruecklauf.component.html',
	styleUrls: ['./ruecklauf.component.scss'],
})
export class RuecklaufComponent extends HandlesBackdropClickAndEscapeKey<ChangeVorgangStatusDialogComponent> implements OnInit, OnDestroy {

	@Input() vorgaenge: VorgangResource[];
	@Input() aktenStandorte: Map<string,string>;
	@Input() headline: string;

	vorgangStatusLabel = VorgangStatusLabel;
	vorgangsTypen: VorgangTypLabel;

	private subscriptions: Subscription[] = [];

	constructor(private router: Router,
	            private vorgangFacade: VorgangFacade,
	            protected dialogService: DialogService,
	            private actionListener: ActionsSubject,
	            private apiRootFacade: ApiRootFacade) {
		super(dialogService);
	}

	ngOnInit() {
		this.subscriptions.push(this.actionListener.pipe(
			ofType(VorgangActions.MARKED_SUCCESS)).subscribe(_value => {
				this.vorgangFacade.loadVorgaengeInRuecklauf();
			},
		));

		this.subscriptions.push(this.apiRootFacade.getCurrentApiRoot().subscribe(apiRoot =>
			this.vorgangsTypen = apiRoot.features.lfpr ? VorgangTypLabelLFPR : VorgangTypLabelSchiff,
		));
	}

	ngOnDestroy() {
		this.subscriptions.forEach(s => s.unsubscribe());
	}

	openStatusDialog(vorgang: VorgangResource) {
		this.matDialogRef = this.dialogService.openEditorDialog(this, ChangeVorgangStatusDialogComponent, {
			data: {
				vorgang: vorgang,
			},
		});
	}

	goToVorgang(vorgang: VorgangResource) {
		this.vorgangFacade.setSelectedVorgang(vorgang);
		this.router.navigate(['/verfahren', btoa(getUrl(vorgang, VorgangLinkRel.VERFAHREN)), {
			outlets: {
				'vorgang': btoa(getUrl(vorgang)),
			},
		}]);
	}

	getStandOrtAkteForVorgang(vorgang:VorgangResource){
		return this.aktenStandorte.get(vorgang._links.self.href);
	}
}
