import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {getUrl, hasLink} from '@ngxp/rest';
import {UserFacade, UserResource} from '@schir-int-client/user-shared';
import {
	initialState,
	VorgangActions,
	VorgangFacade,
	VorgangLinkRel,
	VorgangResource,
} from '@schir-int-client/vorgang-shared';
import {StateResource} from '@schir-int-client/ngrx-helpers';
import {combineLatest, Observable, of, Subscription} from 'rxjs';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import {Moment} from 'moment/moment';
import {UntypedFormControl, Validators} from '@angular/forms';
import * as moment from 'moment';
import {first, map} from 'rxjs/operators';
import {ofType} from '@ngrx/effects';
import {ActionsSubject} from '@ngrx/store';
import {VerfahrenFacade, VerfahrenResource} from '@schir-int-client/verfahren-shared';


@Component({
	selector: 'schir-int-client-wiedervorlage-list',
	templateUrl: './wiedervorlage-list.component.html',
	styleUrls: ['./wiedervorlage-list.component.scss'],
})
export class WiedervorlageListComponent implements OnInit, OnDestroy {

	vorgaenge: Observable<StateResource<VorgangResource[]>>;
	aktenStandorte = new Map<string, string>();

	vonDatumControl = new UntypedFormControl(initialState.datumsBereich.anfangsDatum, Validators.required);
	bisDatumControl = new UntypedFormControl(initialState.datumsBereich.endDatum, Validators.required);

	private subscriptions: Subscription[] = [];

	constructor(
		private userFacade: UserFacade,
		private vorgangFacade: VorgangFacade,
		private router: Router,
		private actionListener: ActionsSubject,
		private verfahrenFacade: VerfahrenFacade,
	) {}

	ngOnInit() {
		const von = this.vonDatumControl.value ? moment(this.vonDatumControl.value) : initialState.datumsBereich.anfangsDatum;
		const bis = this.bisDatumControl.value ? moment(this.bisDatumControl.value) : initialState.datumsBereich.endDatum;

		this.vorgaenge = combineLatest([this.vorgangFacade.getVorgaengeInWiedervorlage(von, bis), this.verfahrenFacade.verfahren])
			.pipe(map(([vorgaenge, verfahrenList]) => {
				vorgaenge.resource?.forEach((vorgang: VorgangResource) => {
					const verfahren: VerfahrenResource = verfahrenList.resource?._embedded['verfahrenList'].find(v => v._links.self.href === vorgang.verfahrenId);
					if (verfahren) {
						this.aktenStandorte.set(vorgang._links.self.href, verfahren.stammdaten.standortAkte);
					}
				});
				return vorgaenge;
			}));

		this.vorgangFacade.getDatumsBereich().pipe(first()).subscribe(value => {
			if (value.anfangsDatum.year() === 1970) {
				this.setVonDatum();
			} else {
				this.vonDatumControl.setValue(value.anfangsDatum);
				this.bisDatumControl.setValue(value.endDatum);
				this.vorgangFacade.refreshWiedervorlagen(value.anfangsDatum, value.endDatum);
			}
		});

		this.subscriptions.push(this.actionListener.pipe(
			ofType(VorgangActions.MARKED_SUCCESS)).subscribe(() => {
				this.vorgangFacade.refreshWiedervorlagen(von, bis);
			},
		));
	}

	ngOnDestroy() {
		this.subscriptions.forEach(s => s.unsubscribe());
	}

	/**
	 * Setzt das Von-Datum auf das Datum der ältesten Wiedervorlage
	 * @private
	 */
	private setVonDatum() {
		this.subscriptions.push(this.vorgaenge.pipe(first(value => value.loaded))
			.subscribe((value: StateResource<VorgangResource[]>) => {
				if (value.resource && value.resource.length > 0) {
					this.vonDatumControl.setValue(value.resource[0].wiedervorlageDatum);
				}
			}));
	}

	/**
	 * Initialisiert einen Reload der Wiedervorlagen, wenn über den Datepicker oder per manueller Eingabe ein anderes Datum ausgewählt wurde.
	 *
	 * @param event
	 */
	dateChosen(event: MatDatepickerInputEvent<Moment>) {
		const von = this.vonDatumControl.value ? moment(this.vonDatumControl.value) : initialState.datumsBereich.anfangsDatum;
		const bis = this.bisDatumControl.value ? moment(this.bisDatumControl.value) : initialState.datumsBereich.endDatum;

		if (event.value && !this.vonDatumControl.errors && !this.bisDatumControl.errors) {
			if (von.isAfter(bis)) {
				return;
			}
			const dateFormat = 'YYYY-MM-DD';
			const datePattern = /^[12]\d{3}-[01]\d-[0123]\d$/;

			const vonString: string = von.format(dateFormat);
			const bisString: string = bis.format(dateFormat);

			if (datePattern.test(vonString) && datePattern.test(bisString)) {
				this.vorgangFacade.refreshWiedervorlagen(von, bis);
			}
		}
	}

	/**
	 * Achtung: eine Link Rechtspfleger scheint aktuell gar nicht gesetzt werden zu können.
	 * @param vorgang
	 */
	getRechtspfleger(vorgang: VorgangResource): Observable<UserResource> {
		if (hasLink(vorgang, VorgangLinkRel.RECHTSPFLEGER)) {
			return this.userFacade.getUserByUri(vorgang, VorgangLinkRel.RECHTSPFLEGER);
		}
		return of(null);
	}

	navigateToVorgang(vorgang: VorgangResource) {
		this.vorgangFacade.setSelectedVorgang(vorgang);
		this.router.navigate(['/verfahren', btoa(getUrl(vorgang, VorgangLinkRel.VERFAHREN)), {
			outlets: {
				'vorgang': btoa(getUrl(vorgang)),
			},
		}]);
	}

	loadFristenListe() {
		this.vorgangFacade.loadFristenListe();
	}

	getStandOrtAkteForVorgang(vorgang: VorgangResource) {
		return this.aktenStandorte.get(vorgang._links.self.href);
	}
}
