import {formatDate} from '@angular/common';
import {AbstractControl, UntypedFormGroup, ValidatorFn} from '@angular/forms';
import {MatDateFormats} from '@angular/material/core';
import {getEmbeddedResource, getUrl, Resource} from '@ngxp/rest';
import {isNil, isNull, mapValues} from 'lodash-es';
import * as moment from 'moment';
import {VorgangListResource, VorgangMarkedSuccessAction, VorgangResource} from '@schir-int-client/vorgang-shared';
import {KontaktAnrede, KontaktResource} from '../../../adressverwaltung-shared/src/lib/adressverwaltung.model';
import {FachlicheSpalte, RegisterName} from '../../../register-shared/src/lib/register.model';

export const SCHIR_DATE_FORMATS: MatDateFormats = {
	parse: {
		dateInput: 'DD.MM.YYYY',
	},
	display: {
		dateInput: 'DD.MM.YYYY',
		monthYearLabel: 'MMM YYYY',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'MMMM YYYY',
	},
};

export function compareDateString(dateString1: string, dateString2: string) {
	if (isNull(dateString1) && (isNull(dateString2))) {
		return 0;
	}
	if (isNull(dateString1)) {
		return 1;
	}
	if (isNull(dateString2)) {
		return -1;
	}
	return dateString1.localeCompare(dateString2);
}

export function objContainsOnlyNullOrEmptyExcept(obj, exceptions: any[]): boolean {
	for (const [attr, value] of Object.entries(obj)) {
		if (!exceptions.includes(attr) && value != null && value !== '') {
			return false;
		}
	}
	return true;
}

export function objContainsOnlyNullOrEmpty(obj): boolean {
	for (const [, value] of Object.entries(obj)) {
		if (value != null && value !== '') {
			return false;
		}
	}
	return true;
}

export function objContainsNullOrEmpty(obj): boolean {
	for (const [, value] of Object.entries(obj)) {
		if (isNil(value) || value === '') {
			return true;
		}
	}
	return false;
}

export function sameUri(resourceA: Resource, resourceB: Resource): boolean {
	if (!isNil(resourceA) && !isNil(resourceB)) {
		if (getUrl(resourceA) === getUrl(resourceB)) {
			return true;
		}
	}
	return false;
}

export function format(date: Date | moment.Moment | string): string {
	const dateFormat: string = 'DD.MM.YYYY';

	if (isNil(date)) {
		return null;
	}
	if (date.hasOwnProperty('format')) {
		return formatDate(<Date>date, dateFormat, 'de');
	} else {
		return moment(<moment.Moment>date).format(dateFormat);
	}
}

export function getTouchedControlNames(form: UntypedFormGroup): string[] {
	let controls: string[] = [];

	for (const controlName in form.controls) {
		const formControl: AbstractControl = form.controls[controlName];

		if (formControl.touched) {
			controls.push(controlName);
		}
	}
	return controls;
}

export function convertFirstLetterUpperCase(string: string): string {
	if (isNil(string)) {
		return null;
	}
	return string.charAt(0).toUpperCase() + string.substring(1, string.length);
}

export function objToString(obj: any): string {
	return isNil(obj) ? '' : obj.toString();
}

export function replaceResourceInMap(resourceMap: { [verfahrenUri: string]: Resource[] }, resourceToReplace: Resource): { [verfahrenUri: string]: Resource[] } {
	return mapValues(resourceMap, (vorgaenge) => vorgaenge.map(vorgang => sameUri(vorgang, resourceToReplace) ? resourceToReplace : vorgang));
}

export function replaceResourceInListResource(listResource: Resource, toReplaceResource: Resource, listLinkRel: string): Resource {
	return createListResource(listResource, getNewItemsForList(listResource, toReplaceResource, listLinkRel), listLinkRel);
}

function getNewItemsForList(listResource: Resource, toReplaceResource: Resource, listLinkRel: string): Resource[] {
	let items: Resource[] = [];
	getEmbeddedResource<Resource[]>(listResource, listLinkRel).forEach(listItem =>
		sameUri(toReplaceResource, listItem) ? items.push(toReplaceResource) : items.push(listItem));
	return items;
}

function createListResource(listResource: Resource, resources: Resource[], listLinkRel: string): Resource {
	let _embedded = {};
	_embedded[listLinkRel] = resources;
	return { _embedded, _links: listResource._links };
}

export function appendVorgaengeForRueckLauf(state, action): VorgangListResource {
	if (state.vorgaengeInRuecklauf && (<VorgangMarkedSuccessAction>action).vorgang.status === 'RUECKLAUF') {
		let vorgaengeInRuecklauf: VorgangResource[] = getEmbeddedResource(state.vorgaengeInRuecklauf, 'vorgangList');
		if (vorgaengeInRuecklauf) {
			vorgaengeInRuecklauf = Array.isArray(vorgaengeInRuecklauf) ? [...vorgaengeInRuecklauf] : [];
			vorgaengeInRuecklauf.push((<VorgangMarkedSuccessAction>action).vorgang);
		} else {
			vorgaengeInRuecklauf = [(<VorgangMarkedSuccessAction>action).vorgang];
		}
		return createListResource(state.vorgaengeInRuecklauf, vorgaengeInRuecklauf, 'vorgangList');
	}
	return null;
}

export function addAriaAttributesToMatSelect(): void {
	const selectElements: HTMLCollectionOf<any> = document.getElementsByTagName('mat-select');
	for (let i = 0; i < selectElements.length; i++) {
		let firstChild = selectElements[i].firstChild;
		if (firstChild) {
			let targetElement = firstChild.firstChild;
			if (targetElement) {
				targetElement.setAttribute('aria-haspopup', 'listbox');
			}
		}
	}
}

export enum KontaktLabelType {
	DEFAULT,
	ABC_STANDARD,
	GLAEUBIGER,
	NOTAR,
	VERTRETER,
	EIGENTUEMER,
	ONLY_FIRMENNAME
}

const SPACE = ' ';
const COMMA = ', ';
const BREAK = '\n';

/**
 * Erstellt ein textuelle Darstellung für den entsprechenden Kontakt nach dem angegebenen Typ.
 * Der Typ gibt an welche Felder verwendet werden und in welcher Form sie verknüpft werden.
 * WICHTIG! Neue Darstellungen für Kontakte hier erweitern, um leichter generalisieren zu können
 */
export function buildKontaktLabel(kontakt: KontaktResource, type: KontaktLabelType = KontaktLabelType.DEFAULT, isLFPR?: boolean) {
	switch (type) {
		case KontaktLabelType.DEFAULT:
			if (kontakt.juristischePerson) {
				return join(SPACE, kontakt.firmenName);
			} else {
				const name = join(SPACE, kontakt.namenszusatz, kontakt.titel, kontakt.vorname, kontakt.name);
				const geburtsname = embrace('geboren ', kontakt.geburtsname, '');

				return join(COMMA, name, geburtsname);
			}
		case KontaktLabelType.ABC_STANDARD:
			if (kontakt.juristischePerson) {
				return join(SPACE, kontakt.firmenName);
			} else {
				const namenszusatz = join(SPACE, kontakt.namenszusatz, kontakt.titel);

				return join(COMMA, kontakt.name, kontakt.vorname, namenszusatz);
			}
		case KontaktLabelType.GLAEUBIGER:
			if (kontakt.juristischePerson) {
				if (isLFPR) {
					const firmenNamen = join(SPACE, kontakt.firmenName, kontakt.firmenName1, kontakt.firmenName2);
					const sitz = join(COMMA, kontakt.sitz, kontakt.land);
					const amtsgericht = kontakt.zustaendigesAmtsgericht ? join(SPACE, 'Register: Amtsgericht', kontakt.zustaendigesAmtsgericht) : undefined;
					const register = join(SPACE, amtsgericht, kontakt.handelsregisterNummer);

					return join(COMMA, firmenNamen, sitz, register);
				} else {
					return join(SPACE, kontakt.firmenName, kontakt.firmenName1, kontakt.firmenName2);
				}
			} else {
				if (isLFPR) {
					const name = join(SPACE, kontakt.titel, kontakt.vorname, kontakt.name, kontakt.namenszusatz);
					const geburtsname = embrace('geb. ', kontakt.geburtsname, '');
					const geburtsdatum = embrace('geboren am ', format(kontakt.geburtsDatum), '');

					return join(COMMA, name, geburtsname, geburtsdatum, kontakt.stadt, kontakt.land);
				} else {
					const name = join(SPACE, kontakt.namenszusatz, kontakt.titel, kontakt.vorname, kontakt.name);
					const geburtsname = embrace('geboren ', kontakt.geburtsname, '');

					return join(COMMA, name, geburtsname);
				}
			}
		case KontaktLabelType.NOTAR:
			if (kontakt.juristischePerson) {
				const firmenname = join(SPACE, kontakt.firmenName, kontakt.firmenName1, kontakt.firmenName2);
				const amtsgericht = join(SPACE, kontakt.zustaendigesAmtsgericht, kontakt.handelsregisterNummer, embrace('(', kontakt.land, ')'));

				return join(COMMA, firmenname, kontakt.sitz, amtsgericht);
			} else {
				let name: string;

				if (isLFPR) {
					const prefix = kontakt.anrede == KontaktAnrede.FRAU ? 'Notarin' : 'Notar';
					name = join(SPACE, prefix, kontakt.titel, kontakt.vorname, kontakt.name, kontakt.namenszusatz);

				} else {
					name = join(SPACE, kontakt.titel, kontakt.vorname, kontakt.name, kontakt.namenszusatz);
				}

				return join(COMMA, name, kontakt.stadt);
			}
		case KontaktLabelType.VERTRETER:
			if (kontakt.juristischePerson) {
				const stadt = join(SPACE, kontakt.stadt, kontakt.zustaendigesAmtsgericht, kontakt.handelsregisterNummer);

				return join(COMMA, kontakt.firmenName, stadt);
			} else {
				const name = join(SPACE, kontakt.vorname, kontakt.name);
				const geburtsdatum = embrace('geb. ', new Date(kontakt.geburtsDatum).toLocaleDateString('de-DE'), '');

				return join(COMMA, name, geburtsdatum);
			}
		case KontaktLabelType.EIGENTUEMER:
			if (kontakt.juristischePerson) {
				const firmenName = join(SPACE, kontakt.firmenName, kontakt.firmenName1, kontakt.firmenName2);
				const land = embrace('(', kontakt.land, ')');
				const amtsgericht = join(SPACE, kontakt.zustaendigesAmtsgericht, kontakt.handelsregisterNummer, land);

				return join(COMMA, firmenName, kontakt.sitz, amtsgericht);
			} else {
				const name = join(SPACE, kontakt.namenszusatz, kontakt.titel, kontakt.vorname, kontakt.name);
				const geburtsname = embrace('geboren ', kontakt.geburtsname, '');

				return join(COMMA, name, geburtsname);
			}
		case KontaktLabelType.ONLY_FIRMENNAME:
			let separator = isLFPR ? SPACE : BREAK;
			return join(separator, kontakt.firmenName, kontakt.firmenName1, kontakt.firmenName2);
	}
}

function join(separator: string, ...values: string[]): string {
	return values.filter(Boolean).join(separator);
}

function embrace(prefix: string, value: string, suffix: string): string {
	return [value].filter(Boolean).map(x => prefix + x + suffix).join('');
}

export interface Selectable {
	label: string,
	value: any,
	disabled?: boolean
}

export interface SpaltenFeld {
	name: string;
	label: string;
	type?: string;
	defaultValue?: Selectable;

	validators?: Array<ValidatorFn>;
}

export interface SelectableSpaltenFeld extends SpaltenFeld {
	values?: string[];
}

export function adjustUrNrTypForLfpr(registerName: RegisterName, fachlicheSpalte: FachlicheSpalte) {
	if (registerName == RegisterName.LR) {
		let urNrTypFeld = fachlicheSpalte.platzhalterFelder.find((value) => value.name == 'urNrTyp');
		urNrTypFeld['values'] = [{
			label: 'UVZ-Nr.', value: 'UVZ-Nr.',
		}, {
			label: 'URNr.', value: 'URNr.',
		}];
		urNrTypFeld.defaultValue.label = 'UVZ-Nr.';
	}
}
