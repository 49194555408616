<h2 mat-dialog-title>Neue Vorlage anlegen</h2>
<form data-test-id="add-vorlage-dialog"
	  [formGroup]="form">

	<mat-dialog-content>
		<mat-form-field appearance="outline" floatLabel="always">
			<mat-label>Beschreibung</mat-label>
			<input matInput formControlName="name" name="description" required
				   data-test-id="description"/>
			<mat-error>Bitte geben Sie die interne Bezeichnung der Vorlage ein.</mat-error>
		</mat-form-field>
		<schir-int-client-vorlage-file-upload (fileChanged)="updateVorlage($event)">
		</schir-int-client-vorlage-file-upload>
		<mat-error *ngIf="form.controls['file'].errors?.invalidExtension">
			Es werden nur CSV- oder DOCX-Dateien unterstützt.
		</mat-error>
	</mat-dialog-content>
	<mat-dialog-actions>
		<button mat-stroked-button (click)="onCancel()" data-test-id="cancel-button" class="focus-box-shadow-dark">Abbrechen</button>
		<button mat-flat-button color="primary" id="createVorlage" (click)="onSave()" type="submit" [disabled]="!isValid()"
				data-test-id="vorlage-einpflegen-button" class="focus-box-shadow-dark">Vorlage einpflegen
		</button>
	</mat-dialog-actions>
</form>
