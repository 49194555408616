export enum VorgangMessages {
	CONFIRM_CREATE_VORGANG = 'Soll der Posteingang als {0} übernommen werden?',
	VORGANG_CREATED = 'Der Vorgang wurde erfolgreich angelegt',
	VORGAENGE_TO_RECHTSPFLEGE_ASSIGNED = '{1} Vorgänge wurden an die Rechtspflege übergeben.',
	TO_RECHTSPFLEGE_MOVED = '{1} Vorgänge wurden an die Rechtspflege übergeben.',
	ASSIGN_TO_POSTEINGANG = 'Wollen Sie den Posteingang dem ausgewählten Vorgang zuordnen?',
	ASSIGN_TO_POSTEINGANG_SUCCESS = 'Der Posteingang wurde dem Vorgang erfolgreich hinzugefügt',
	WIEDERVORLAGE_DATE_MISSING = 'Das Datumsfeld darf bei einer Wiedervorlage nicht leer sein'
}

export enum VorgangStatusLabel {
	NEU = 'Neu',
	RECHTSPFLEGE = 'Rechtspflege',
	RUECKLAUF = 'Rücklauf',
	WIEDERVORLAGE = 'Wiedervorlage',
	ABGESCHLOSSEN = 'Abgeschlossen'
}

export type VorgangTypLabel = typeof VorgangTypLabelSchiff | typeof VorgangTypLabelLFPR;

export enum VorgangTypLabelSchiff {
	NEUEINTRAGUNG = 'Neueintragung',
	AUSFLAGGUNG = 'Ausflaggung',
	FLAGGENWECHSEL = 'Flaggenwechsel',
	NAMENSAENDERUNG = 'Namensänderung (Abt.I)',
	ABTEILUNG_I_SONST = 'Abteilung I sonst.',
	EIGENTUMSVERAENDERUNG = 'Eigentumsveränderung',
	INSOLVENZ = 'Insolvenz',
	HYPOTHEK = 'Hypothek',
	LOESCHUNG = 'Löschung Schiff',
	SONSTIGES = 'Sonstiges',
	ALLGEMEIN = 'Allgemein',
	REGISTERAUSDRUCK = 'Registerausdruck',
}

export enum VorgangTypLabelLFPR {
	LFPR_NEUANMELDUNG = 'Neuanmeldung',
	LFPR_EIGENTUMSWECHSEL = 'Eigentumswechsel',
	LFPR_NAMENSAENDERUNG = 'Namensänderung Eigentümer',
	LFPR_ANGABENAENDERUNG = 'Änderung Angaben zum Luftfahrzeug',
	LFPR_WIDERSPRUCH = 'Widerspruch nach § 82 LuftfzgG',
	LFPR_ZWANGSVERSTEIGERUNGSVERMERK = 'Zwangsversteigerungsvermerk',
	LFPR_INSOLVENZVERMERK = 'Insolvenzvermerk (Verfügungsbeschränkung)',
	LFPR_INSOLVENZVERMERK_EROEFFNUNG = 'Insolvenzvermerk (Eröffnung)',
	LFPR_RECHTSHÄNGIGKEITSVERMERK = 'Rechtshängigkeitsvermerk',
	LFPR_LOESCHUNG_WIDERSPRUCH = 'Löschung Widerspruch',
	LFPR_LOESCHUNG_VERSTEIGERUNG_INSOLVENZVERMERK = 'Löschung Versteigerungs- oder Insolvenzvermerk',
	LFPR_LOESCHUNG_RECHTSHAENGIGKEITSVERMERK = 'Löschung Rechtshängigkeitsvermerk',
	LFPR_LOESCHUNG_SCHUTZVERMERK = 'Löschung Schutzvermerk',
	LFPR_LOESCHUNG_LUFTFAHRZEUG = 'Löschung des Luftfahrzeugs',
	LFPR_REGISTERPFANDRECHT = 'Registerpfandrecht',
	LFPR_GESAMTREGISTERPFANDRECHT = 'Gesamtregisterpfandrecht',
	LFPR_REGISTERPFANDRECHT_HOECHSTBETRAG = 'Registerpfandrecht mit Höchstbetrag',
	LFPR_REGISTERPFANDRECHT_ZWANGSVOLLSTRECKUNG = 'Registerpfandrecht durch Zwangsvollstreckung',
	LFPR_VERAENDERUNG_REGISTERPFANDRECHTE = 'Veränderung an Registerpfandrechten',
	LFPR_LOESCHUNG_REGISTERPFANDRECHT = 'Löschung Registerpfandrecht',
	LFPR_LOESCHUNG_REGISTERPFANDRECHT_BLATT = 'Löschung Registerpfandrecht in einem Blatt (= Entlassung aus der Mithaft)',
	LFPR_SONSTIGES = 'Sonstiges',
	ALLGEMEIN = 'Allgemein',
}
