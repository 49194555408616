import {Injectable} from '@angular/core';
import {Actions, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {RegisterAusdruckType, VerfahrenFacade} from '@schir-int-client/verfahren-shared';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {RegisterBlattActions} from '../../../register-shared/src/lib/register.actions';
import {SpaltenName} from '../../../register-shared/src/lib/register.model';
import {
	AddChangeEntryAction,
	AddEntryBatchAction,
	AddVermerkEntryAction,
	CreateUmschreibungEntryAction,
	DeleteEntryAction,
	RefreshStateAction,
	UpdateChangeEntryAction,
} from './register-change.actions';
import {
	BatchEntry,
	ChangeEntry,
	ChangeEntryResource,
	ChangeEntryVermerk,
	ChangeEntryVermerkResource,
} from './register-change.model';
import {RegisterFeatureState} from './register-change.selectors';

@Injectable({ providedIn: 'root' })
export class RegisterBlattChangeFacade {

	constructor(private store: Store<RegisterFeatureState>,
	            private actions: Actions,
	            private verfahrenFacade: VerfahrenFacade) { }

	addChangeEntry(entry: ChangeEntry) {
		this.store.dispatch(new AddChangeEntryAction(entry));
	}

	addVermerk(spaltenName: SpaltenName, vermerk: string): void {
		const vermerkEntry: ChangeEntryVermerk = {
			spaltenName,
			freigabeText: null,
			positionInSpalte: null,
			spaltenWert: {
				vermerk,
			},
		};
		this.store.dispatch(new AddVermerkEntryAction(vermerkEntry));
	}

	createUmschreibungEntry(entryResource: ChangeEntryResource, newEntry: ChangeEntry) {
		this.store.dispatch(new CreateUmschreibungEntryAction(entryResource, newEntry));
	}

	deleteEntry(entry: ChangeEntryResource) {
		this.store.dispatch(new DeleteEntryAction(entry));
	}

	editVermerk(vermerk: ChangeEntryVermerkResource) {
		this.updateChangeEntry(vermerk);
	}

	updateChangeEntry(entry: ChangeEntryResource) {
		this.store.dispatch(new UpdateChangeEntryAction(entry));
	}

	downloadAusdruck(type: RegisterAusdruckType) {
		this.verfahrenFacade.downloadAusdruck(type);
	}

	registerChangeSaved(): Observable<void> {
		return this.actions.pipe(ofType(RegisterBlattActions.REGISTERBLATT_CHANGE_SAVED), map(() => undefined));
	}

	refreshState() {
		this.store.dispatch(new RefreshStateAction());
	}

	addBatch(entries: BatchEntry[]) {
		this.store.dispatch(new AddEntryBatchAction(entries));
	}
}
