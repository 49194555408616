<div class="focus-border-dark">
	<li class="c-list__item" data-test-id="eintrag">
		<a *ngIf="vorgang" class="c-list__link" tabindex="0"
		   [attr.data-test-id]="'select-wiedervorlage '+ aktenzeichen"
		   (click)="vorgangSelected.emit(vorgang)" (keyup.enter)="vorgangSelected.emit(vorgang)">
			<div class="c-row c-row--header c-wrap">
				<schir-int-client-aktenzeichen data-test-id="aktenzeichen" class="c-row__item c-row__item--header" [vorgang]="vorgang"></schir-int-client-aktenzeichen>
				<span class="c-row__item c-row__item--secondary"
					  data-test-id="vorgang-status">{{vorgang.status | enumToLabel: vorgangStatusLabel }}</span>
			</div>
			<div class="c-row c-wrap">
				<span class="c-row__item"
					  data-test-id="vorgang-typ">{{vorgang.typ | enumToLabel: vorgangsTypen }}</span>
				<span class="c-row__item"
					  data-test-id="vorgang-datum">{{vorgang.wiedervorlageDatum | formatDate }}</span>
			</div>
			<div class="c-row c-row--secondary c-wrap">
				<span class="c-row__item" data-test-id="notiz">{{vorgang.notiz}}</span>
				<span class="c-row__item" *ngIf="assignedRechtspfleger" data-test-id="rechtspfleger">
					{{assignedRechtspfleger.lastName}}
				</span>
			</div>
			<div *ngIf="aktenStandort" class="c-row c-row--secondary c-wrap">
				<span class="c-row__item"  data-test-id="wiedervorlage-standortAkte">
					Standort Akte: {{aktenStandort}}
				</span>
			</div>
		</a>
		<div class="c-button-bar c-button-bar--overlay">
			<schir-int-client-button-round data-test-id="open-status-dialog-button" (click)="openStatusDialog(vorgang)"
										   popup="dialog" materialIcon="send" toolTip="Status des Vorgangs setzen"
										   cssClass="focus-box-shadow-dark">
			</schir-int-client-button-round>
		</div>
	</li>
</div>
