import {Link, Resource, ResourceUri} from '@ngxp/rest';
import {PosteingangResource} from '@schir-int-client/posteingang-shared';
import {RegisterName} from '@schir-int-client/register-shared';
import {KontaktResource} from '@schir-int-client/adressverwaltung-shared';
import {VerfahrenSearchKey} from './verfahren.messages';

export enum VerfahrenStatus {
	VORLAEUFIG = 'VORLAEUFIG',
	EINGETRAGEN = 'EINGETRAGEN',
	GELOESCHT = 'GELOESCHT',
	GESCHLOSSEN = 'GESCHLOSSEN',
	ABGELEHNT = 'ABGELEHNT'
}

export interface VerfahrenSearchQuery {
	searchString: string,

	searchIn: VerfahrenSearchKey[],

	filterByStatus: VerfahrenStatus[],

	limit?: number
}

export interface NewVerfahren {
	register: RegisterName;
	blattNummer: number;
}

export interface VerfahrenStammdaten {
	aktenzeichen: string;
	schiffName: string;
	unsignedSchiffName: string;
	imoNummer: string;
	unsignedImoNummer: string;
	eigentuemerNamen: string;
	unsignedEigentuemerNamen: string;
	unterscheidungssignal: string;
	unsignedUnterscheidungssignal: string;
	historischeSchiffNamen: string;
	standortAkte: string;
	arKennzeichen: string;
	eintragungszeichen: string;
	unsignedEintragungszeichen: string;
}

export interface Verfahren extends NewVerfahren {
	aktenzeichen: string;
	amtsgerichtNummer: number;
	status: VerfahrenStatus;
	posteingangResource: PosteingangResource;
	aufschrift: string;
	notiz: string;
	assignTo: ResourceUri;
	verfahrenKontakte: KontaktResource[];
	stammdaten: VerfahrenStammdaten;
	properties?: VerfahrenProperty[];
}

export interface VerfahrenProperty {
	key: string;
	value: string;
	updatedAt: string;
}

// Achtung: wird sowohl für die Liste als auch als einzelnes Verfahren benutzt
export interface VerfahrenListResource extends Verfahren {
	_embedded: {},
	_links: {
		self: {
			href: string
		}
	},
	page: {
		totalElements: number,
		size: number
	}
}

export interface ZaehlblattEntry extends Resource {
	type: string,
	createdAt: string,
}

export enum ZaehlblattAktionTyp {
	EIGENTUEMER = 'EIGENTUEMER',
	LFZ_NEU = 'LFZ_NEU',
	LOESCHUNG_PFANDRECHT = 'LOESCHUNG_PFANDRECHT',
	PFANDRECHT_NEU = 'PFANDRECHT_NEU',
	PFANDRECHTE_AN_BESTEHENDEN_LFZ = 'PFANDRECHTE_AN_BESTEHENDEN_LFZ',
	SCHLIESSUNG_DES_BLATTES = 'SCHLIESSUNG_DES_BLATTES',
	EINGEREICHTE_URKUNDE_ZUM_REGISTER = 'EINGEREICHTE_URKUNDE_ZUM_REGISTER',
	SONSTIGE = 'SONSTIGE',
	EINGEREICHTE_URKUNDEN_SSR = 'EINGEREICHTE_URKUNDEN_SSR',
	EINGEREICHTE_URKUNDEN_BSR = 'EINGEREICHTE_URKUNDEN_BSR',
	EINGEREICHTE_URKUNDEN_SBR = 'EINGEREICHTE_URKUNDEN_SBR',
	EINGEREICHTE_URKUNDEN_AR = 'EINGEREICHTE_URKUNDEN_AR',
}

// iterating Object.values(ZaehlblattAktionTyp) would not guarantee the order
export const orderedZaehlblattAktionTypes = [
	ZaehlblattAktionTyp.EIGENTUEMER,
	ZaehlblattAktionTyp.LFZ_NEU,
	ZaehlblattAktionTyp.LOESCHUNG_PFANDRECHT,
	ZaehlblattAktionTyp.PFANDRECHT_NEU,
	ZaehlblattAktionTyp.PFANDRECHTE_AN_BESTEHENDEN_LFZ,
	ZaehlblattAktionTyp.SCHLIESSUNG_DES_BLATTES,
	ZaehlblattAktionTyp.EINGEREICHTE_URKUNDE_ZUM_REGISTER,
	ZaehlblattAktionTyp.SONSTIGE,
	ZaehlblattAktionTyp.EINGEREICHTE_URKUNDEN_SSR,
	ZaehlblattAktionTyp.EINGEREICHTE_URKUNDEN_BSR,
	ZaehlblattAktionTyp.EINGEREICHTE_URKUNDEN_SBR,
	ZaehlblattAktionTyp.EINGEREICHTE_URKUNDEN_AR,
];

export type ZaehlblattAktionen = Record<ZaehlblattAktionTyp, number>;

export type RegisterAusdruckType = 'einfach' | 'beglaubigt' | 'entwurf';

export interface VerfahrenResource extends Verfahren, Resource {}

export interface ZaehlblattResource extends Resource {
	_embedded: {
		zaehlblattEntryList: Array<ZaehlblattEntry>
	},
	_links: {
		self: {
			href: string
		},
		add: {
			href: string
		},
		[key: string]: Link;
	};
}
