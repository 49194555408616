<li class="c-list__item focus-border-dark"  [ngClass]="{'c-list__item--active': selected }"
	[attr.data-test-id]="'vorgang-root '+ (vorgang | testIdFromResource)">
	<a class="c-list__link" data-test-id="set-selected-vorgang" (click)="setSelectedVorgang()"
		[routerLink]="['.',{ outlets: { vorgang: getVorgang(), verfuegung: null, aufgabe: null } }]" [elementFocus]="selected">
		<div *ngIf="(selected)" class="visually-hidden">selektierter Vorgang</div>
		<div class="c-row c-row--header">
			<span class="c-row__item c-row__item--header" data-test-id="vorgang-typ">{{vorgang.typ | enumToLabel: vorgangsTypen }}</span>
			<span class="c-row__item c-row__item--secondary"
				data-test-id="vorgang-status">{{vorgang.status | enumToLabel: vorgangStatusLabel }}</span>
		</div>
		<div class="c-row">
			<span class="c-row__item" data-test-id="vorgang-datum">{{vorgang.anlageDatum | formatDate }}</span>
			<span *ngIf="vorgang.status===VorgangStatus.WIEDERVORLAGE" class="c-row__item wiedervorlage-datum" data-test-id="wiedervorlage-datum">{{vorgang.wiedervorlageDatum | formatDate }}</span>
		</div>
		<div class="c-row c-row--secondary">
			<span class="c-row__item notiz-text" data-test-id="vorgang-notiz">{{vorgang.notiz}}</span>
		</div>
		<i *ngIf="!selected" class="material-icons c-list__icon" aria-hidden="true" alt="Pfeil rechts">arrow_right</i>
	</a>

	<div class="c-button-bar c-button-bar--overlay">
		<schir-int-client-button-round *ngIf="statusChangeAllowed()" data-test-id="open-status-dialog-button" (click)="openStatusDialog()"
									   popup="dialog" materialIcon="send" toolTip="Status des Vorgangs setzen"
									   cssClass="focus-box-shadow-dark">
		</schir-int-client-button-round>

		<schir-int-client-posteingang-selector [vorgang]="vorgang" [posteingaenge]="posteingaenge | async"
			(loadPosteingaenge)="loadPosteingaenge()">
		</schir-int-client-posteingang-selector>

		<schir-int-client-button-round data-test-id="open-notiz-dialog-button" *ngIf="editNotizAllowed()" (click)="openNotizDialog()"
									   popup="dialog" materialIcon="notes" toolTip="Notiz bearbeiten"
									   cssClass="focus-box-shadow-dark">
		</schir-int-client-button-round>
		<schir-int-client-button-round *ngIf="deletable | async" data-test-id="open-delete-dialog-button" (click)="openDeleteDialog()"
		                               popup="dialog" materialIcon="delete" toolTip="Vorgang löschen"
		                               cssClass="focus-box-shadow-dark">
		</schir-int-client-button-round>
	</div>
</li>
