export enum ApiRootLinkRel {
	ADMIN = 'admin',
	VORGANGS = 'vorgangs',
	POSTEINGANGS = 'posteingangs',
	VERFAHRENS = 'verfahrens',
	KONTAKTS = 'kontakts',
	VORGAENGE_IN_RUECKLAUF = 'vorgaengeInRuecklauf',
	VORGAENGE_IN_WIEDERVORLAGE = 'vorgaengeInWiedervorlage',
	WIEDERVORLAGEN_FRISTEN = 'fristen',
	VORGAENGE_RECHTSPFLEGE = 'vorgaengeFuerRechtspfleger',
	VORLAGES = 'vorlages',
	LANDS = 'lands',
	HEIMATHAFENS = 'heimathafens',
	RECHTSPFLEGERS = 'rechtspflegers',
	SIGNATURE_VALIDATOR_RESPONSES = 'signatureValidationResponses',
	STATISTIK = 'statistik',
	TRANSLATION = 'translation',
	USIGNAL_BY_VALUE = 'usignalByValue',
}
