import {Inject, Injectable} from '@angular/core';
import {Resource, ResourceFactory} from '@ngxp/rest';
import {ENVIRONMENT_CONFIG} from '@schir-int-client/environment';
import {BehaviorSubject, Observable} from 'rxjs';
import {UserResource} from './user.model';

@Injectable({ providedIn: 'root' })
export class UserService {

	private loggedInUserSubject: BehaviorSubject<UserResource> = new BehaviorSubject(null);

	constructor(private resourceFactory: ResourceFactory, @Inject(ENVIRONMENT_CONFIG) private envConfig) {
		this.getUser().subscribe(user => { this.loggedInUserSubject.next(user); });
	}

	readonly loggedInUser$ = this.loggedInUserSubject.asObservable();


	getUser(): Observable<UserResource> {
		return this.resourceFactory.fromId(this.envConfig.userDetailsUrl).get();
	}

	logout(): Observable<void> {
		return this.resourceFactory.fromId(this.envConfig.logoutUrl).get();
	}

	loadUserByResource(resource: Resource, linkRel: string): Observable<UserResource> {
		return this.resourceFactory.from(resource).get(linkRel);
	}
}
