import {
	AfterViewChecked,
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	QueryList,
	ViewChildren,
} from '@angular/core';
import {hasLink} from '@ngxp/rest';
import {
	AdressverwaltungFacade,
	KontaktLinkRel,
	KontaktResource,
	KontaktSearchMode,
	KontaktWithEditMode,
	SearchInputWithModes,
} from '@schir-int-client/adressverwaltung-shared';
import {clamp, isNil} from 'lodash-es';
import {Observable, Subject, Subscription} from 'rxjs';
import {filter, map, take} from 'rxjs/operators';
import {AccessibilityService, ClipboardService} from '@schir-int-client/tech';
import {MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig} from '@angular/material/legacy-dialog';
import {KontaktAssignMenuComponent} from './kontakt-assign-menu/kontakt-assign-menu.component';
import {MailAddressService} from '../../../../../../tech/src/lib/mail-address/mail-address.service';
import {ApiRootFacade} from '@schir-int-client/api-root';


@Component({
	selector: 'schir-int-client-adressverwaltung-kontakt-list',
	templateUrl: './adressverwaltung-kontakt-list.component.html',
	styleUrls: ['./adressverwaltung-kontakt-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdressverwaltungKontaktListComponent implements OnInit, AfterViewChecked, OnDestroy {
	@Input() selectedKontakt: KontaktResource;
	@Input() editMode: boolean;
	@Input() searchParameters: SearchInputWithModes;
	@ViewChildren('kontaktlist', { read: ElementRef }) kontakt_divs: QueryList<ElementRef>;

	@Output() setSelectedKontakt: EventEmitter<KontaktWithEditMode> = new EventEmitter();
	@Output() addKontakt: EventEmitter<void> = new EventEmitter();

	kontakte: Observable<KontaktResource[]>;
	kontaktIdx: number;
	groupChar: string;
	showInactive: boolean = false;
	showAddress: boolean = false;

	allowCreateKontakt: Observable<boolean>;

	private subscriptions: Subscription[] = [];

	private numberItems: number;
	public audioAlert = new Subject<string>();
	allowToCreate: boolean;

	constructor(public adressverwaltungFacade: AdressverwaltungFacade,
	            private accessibilityService: AccessibilityService,
	            private matDialog: MatDialog,
	            private clipboardService: ClipboardService,
	            private mailAddressService: MailAddressService,
	            private apiRootFacade: ApiRootFacade) {}

	ngOnInit() {
		this.showAddress = this.apiRootFacade.isLFPR;
		this.kontakte = this.adressverwaltungFacade.getKontakte(null).pipe(map(value => this.filterOnShowInactive(value)));
		this.subscriptions.push(this.kontakte.subscribe(elem => elem?.forEach((value, index) => {
			if (this.isSelectedKontakt(value)) {
				this.kontaktIdx = index;
				return;
			}
		})));

		this.subscriptions.push(this.adressverwaltungFacade.allowedToCreate.subscribe(v => this.allowToCreate = v));
	}


	ngAfterViewChecked() {
		this.subscriptions.push(this.kontakt_divs.changes.pipe(take(1)).subscribe((kontakt: QueryList<ElementRef>) => {
			let divElem = kontakt.find((_item, index, _array) => this.kontaktIdx == index);
			if (!isNil(divElem)) {
				divElem.nativeElement.scrollIntoView();
				this.adressverwaltungFacade.rendered(divElem);
			}
		}));
	}

	ngOnDestroy() {
		this.subscriptions.forEach(s => s.unsubscribe());
	}

	filterOnShowInactive(kontakte: KontaktResource[]): KontaktResource[] {
		return this.showInactive ? kontakte : kontakte?.filter(kontakt => kontakt.active);
	}

	doSearch(searchInputWithModes: SearchInputWithModes) {
		this.kontakte = this.adressverwaltungFacade.getKontakte(searchInputWithModes.searchString);
		this.kontakte = this.kontakte.pipe(
			filter(kontakte => !isNil(kontakte)),
			map(kontakte => {
				const kontaktResources = kontakte.filter(kontakt => (
					((searchInputWithModes.searchModes.includes(KontaktSearchMode.BEHOERDEN) && kontakt.behoerde) ||
						(searchInputWithModes.searchModes.includes(KontaktSearchMode.JURISTISCH) && kontakt.juristischePerson) ||
						(searchInputWithModes.searchModes.includes(KontaktSearchMode.NATUERLICH) && !kontakt.juristischePerson)) &&
					((searchInputWithModes.searchModes.includes(KontaktSearchMode.INAKTIV) && !kontakt.active) ||
						(searchInputWithModes.searchModes.includes(KontaktSearchMode.AKTIV) && kontakt.active))
				));
				this.numberItems = kontaktResources?.length;
				return kontaktResources;
			}));
	}

	doConfirmUpdate() {
		this.accessibilityService.shout('Suchergebnisse aktualisiert: ' + this.numberItems + (this.numberItems == 1 ? 'Kontakt.' : ' Kontakte.'),
			this.audioAlert);
	}

	newGroupLetter(kontakt: KontaktResource): boolean {
		const char = kontakt.title.charAt(0).toLocaleUpperCase();
		if (char != this.groupChar) {
			this.groupChar = char;
			return true;
		} else {
			return false;
		}
	}

	patchKontaktAndFocus(kontakt: KontaktResource) {
		this.patchKontakt(kontakt);
		setTimeout(() => {
			if (kontakt.active) {
				this.accessibilityService.focusElementById('edit-button');
			} else {
				this.accessibilityService.focusElementById('activate-button');
			}
		});
	}

	patchKontakt(kontakt: KontaktResource) {
		this.setSelectedKontakt.emit(<KontaktWithEditMode>{ kontakt, editMode: this.editMode });
	}

	patchNewKontakt() {
		this.addKontakt.emit(null);
	}

	isNewKontakt(): boolean {
		return !isNil(this.selectedKontakt) && !hasLink(this.selectedKontakt, KontaktLinkRel.SELF);
	}

	isSelectedKontakt(kontakt: KontaktResource) {
		if (this.selectedKontakt && this.selectedKontakt._links) {
			return kontakt._links.self.href == this.selectedKontakt._links.self.href;
		}
		return false;
	}

	copyMailAddressToClipboard(kontakt: KontaktResource) {
		const mailAddress = this.mailAddressService.createMailAddressForKontakt(kontakt);
		this.clipboardService.copyAndShowSnackBar(mailAddress, 'Adressdaten wurden in die Zwischenablage kopiert.');
	}

	openAssignMenu(kontakt: KontaktResource, kontaktDiv: HTMLElement, scrollArea: HTMLElement) {
		const assignWindowHeight = 500; // estimated value - will be moved after assign menu is rendered
		let y = this.getYPos(kontaktDiv) + kontaktDiv.offsetHeight - scrollArea.scrollTop;

		const dialogConfig = new MatDialogConfig();
		dialogConfig.data = kontakt;
		dialogConfig.panelClass = 'kontaktAssignMenu';
		dialogConfig.autoFocus = false;
		dialogConfig.hasBackdrop = false;
		dialogConfig.position = { top: clamp(y, 10, window.innerHeight - assignWindowHeight - 10) + 'px' };

		let dialogRef = this.matDialog.open(KontaktAssignMenuComponent, dialogConfig);
		dialogRef.componentInstance.matDialogRef = dialogRef;
	}

	private getYPos(element: HTMLElement) {
		let y = 0;
		while (element) {
			y += element.offsetTop + element.clientTop - element.scrollTop;
			element = <HTMLElement>element.offsetParent;
		}
		return y;
	}

	toggleKontaktDarstellung() {
		this.showAddress = !this.showAddress;
	}

	protected readonly KontaktLinkRel = KontaktLinkRel;
}
