import {createFeatureSelector, createSelector, MemoizedSelector, MemoizedSelectorWithProps} from '@ngrx/store';
import {DefaultProjectorFn} from '@ngrx/store/src/selector';
import {getEmbeddedResource, getUrl, ResourceUri} from '@ngxp/rest';
import {createEmptyStateResource, StateResource} from '@schir-int-client/ngrx-helpers';
import {sameUri} from '@schir-int-client/tech';
import {isNil, isNull} from 'lodash-es';
import {VorgangListLinkRel} from './vorgang.linkrel';
import {DatumsBereich, VorgangResource} from './vorgang.model';
import {vorgangFeatureState, VorgangRootState} from './vorgang.state';

export const selectFeature = createFeatureSelector<VorgangRootState>(vorgangFeatureState);

export const vorgangStateSelector =
	createSelector(selectFeature, (state: VorgangRootState) => {
		return state.vorgangRoot;
	});

export const vorgaengeByVerfahrenSelector =
	createSelector(selectFeature, (state: VorgangRootState, props) => {
		if (!isNil(state.vorgangRoot.vorgaengeByVerfahren)) {
			return state.vorgangRoot.vorgaengeByVerfahren[props.verfahrenUri];
		}
	});

export const vorgangSelector: MemoizedSelector<object, StateResource<VorgangResource>, DefaultProjectorFn<StateResource<VorgangResource>>> =
	createSelector(selectFeature, (state: VorgangRootState) => {
		return state.vorgangRoot.selectedVorgang;
	});

export const vorgangByUriSelector: MemoizedSelectorWithProps<object, any, StateResource<VorgangResource>> =
	createSelector(selectFeature, (state: VorgangRootState, props) => {
		const selected = state.vorgangRoot.vorgangByUri[props.uri];

		return isNil(selected) ? createEmptyStateResource<VorgangResource>() : selected;
	});

export const selectedVorgangUriSelector: MemoizedSelector<object, ResourceUri, DefaultProjectorFn<ResourceUri>> =
	createSelector(selectFeature, (state: VorgangRootState) => {
		return state.vorgangRoot.selectedVorgangUri;
	});

export const zugeordneteVorgaengeSelector =
	createSelector(selectFeature, (state: VorgangRootState) => {
		if (!isNull(state.vorgangRoot.zugeordneteVorgaenge)) {
			return getEmbeddedResource<VorgangResource[]>(state.vorgangRoot.zugeordneteVorgaenge, VorgangListLinkRel.VORGANG_LIST);
		}
	});

export const zugeordneterVorgangExistsSelector =
	createSelector(selectFeature, (state: VorgangRootState) => {
		if (!isNull(state.vorgangRoot.zugeordneteVorgaenge)) {
			let res = getEmbeddedResource<VorgangResource[]>(state.vorgangRoot.zugeordneteVorgaenge, VorgangListLinkRel.VORGANG_LIST);
			return !isNil(res) && res.length > 0;
		}
		return false;
	});

export const vorgaengeRechtspflegerSelector =
	createSelector(selectFeature, (state: VorgangRootState) => {
		if (!isNull(state.vorgangRoot)) {
			return state.vorgangRoot.vorgaengeRechtspflege;
		}
		return null;
	});

export const vorgaengeInRuecklaufSelector =
	createSelector(selectFeature, (state: VorgangRootState) => {
		if (!isNil(state.vorgangRoot.vorgaengeInRuecklauf)) {
			return getEmbeddedResource<VorgangResource[]>(state.vorgangRoot.vorgaengeInRuecklauf, VorgangListLinkRel.VORGANG_LIST);
		}
		return null;
	});

export const isVorgangSelectedSelector =
	createSelector(selectFeature, (state: VorgangRootState, props) => {
		return state.vorgangRoot.selectedVorgang.loaded && sameUri(state.vorgangRoot.selectedVorgang.resource, props.vorgang);
	});

export const selectedVorgangAndVorgangUriSelector: MemoizedSelector<object, StateResource<VorgangResource>, DefaultProjectorFn<StateResource<VorgangResource>>> =
	createSelector(selectFeature, (state: VorgangRootState) => {
		const selectedVorgange: StateResource<VorgangResource> = state.vorgangRoot.selectedVorgang;
		return {
			...selectedVorgange,
			reload: selectedVorgange.loaded && getUrl(selectedVorgange.resource) !== state.vorgangRoot.selectedVorgangUri,
		};
	});

export const vorgaengeInWiedervorlageSelector: MemoizedSelector<object, StateResource<VorgangResource[]>> = createSelector(selectFeature, (state: VorgangRootState) => {
	const selected = state.vorgangRoot.vorgaengeInWiedervorlage;

	if (isNull(selected.resource)) {
		return { ...selected, resource: [] };
	}
	return {
		...selected,
		resource: <VorgangResource[]>getEmbeddedResource(selected.resource, VorgangListLinkRel.VORGANG_LIST),
	};
});

export const datumsBereichSelector: MemoizedSelector<object, DatumsBereich> = createSelector(selectFeature, (state: VorgangRootState) => {
	return state.vorgangRoot.datumsBereich;
});
