import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {TechModule} from '@schir-int-client/tech';
import {WiedervorlageListComponent} from './wiedervorlage-list/wiedervorlage-list.component';
import {WiedervorlageComponent} from './wiedervorlage-list/wiedervorlage/wiedervorlage.component';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {MatLegacySnackBarModule as MatSnackBarModule} from '@angular/material/legacy-snack-bar';
import {RouterModule} from '@angular/router';
import {AktenzeichenModule} from '@schir-int-client/aktenzeichen';

@NgModule({
	imports: [
		CommonModule,
		TechModule,
		FormsModule,
		ReactiveFormsModule,
		MatFormFieldModule,
		MatInputModule,
		MatButtonModule,
		MatDatepickerModule,
		MatDialogModule,
		MatTooltipModule,
		ClipboardModule,
		MatSnackBarModule,
		RouterModule,
		AktenzeichenModule,
	],
	declarations: [
		WiedervorlageListComponent,
		WiedervorlageComponent,
	],
	exports: [
		WiedervorlageListComponent,
	],
})
export class WiedervorlageModule {}
