import {Injectable} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Aufgabe, AufgabeAnschreibenBody} from '@schir-int-client/aufgabe-shared';

@Injectable()
export class KorrespondenzFormService {
	form: UntypedFormGroup;
	readonly feldDatum = 'datum';
	readonly feldRechtspfleger = 'rechtspfleger';
	readonly feldVorlage = 'vorlageId';

	constructor(private formBuilder: UntypedFormBuilder) {
		this.form = this.formBuilder.group({
			[this.feldVorlage]: new UntypedFormControl(null, Validators.required),
			[this.feldDatum]: new UntypedFormControl(new Date(), Validators.required),
			[this.feldRechtspfleger]: null,
		});
	}

	patchToAufgabe(aufgabe) {
		if (aufgabe.body?.hasOwnProperty('vorlageId')) {
			this.form.patchValue(this.prepareForPatch(aufgabe));
		}
	}

	prepareForPatch(aufgabe: Aufgabe): object {
		return {
			[this.feldVorlage]: (<AufgabeAnschreibenBody>aufgabe.body).vorlageId,
			[this.feldDatum]: (<AufgabeAnschreibenBody>aufgabe.body).datum,
			[this.feldRechtspfleger]: (<AufgabeAnschreibenBody>aufgabe.body).rechtspfleger,
		};
	}
}
