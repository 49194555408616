<fieldset class="grid" *ngIf="formGroup.enabled" data-test-id="aufgabe-messbrief" [formGroup]="bodyGroup">
	<fieldset class="">
		<legend aria-label="Einstellungen zum Messbrief"></legend>
		<div class="row">
			<mat-checkbox color="primary" class="focus-box-shadow-dark" data-test-id="aufgabe-messbrief-checkbox-original"
						  [formControlName]="formServiceClass.feldMessbriefplusUsig">
				Messbrief mit U-Signal versehen
			</mat-checkbox>
		</div>
		<div class="row">
			<mat-checkbox color="primary" class="focus-box-shadow-dark" data-test-id="aufgabe-messbrief-checkbox-original"
						  [formControlName]="formServiceClass.feldMessbriefplusVermerk">
				Eichschein/Schiffszeugnis mit Eintragungsvermerk versehen
			</mat-checkbox>
		</div>
		<div class="row">
			<mat-checkbox color="primary" class="focus-box-shadow-dark" data-test-id="aufgabe-messbrief-checkbox-original"
						  [formControlName]="formServiceClass.feldMessbriefOriginal">
				Original senden/Aushändigen an
			</mat-checkbox>
		</div>
		<fieldset *ngIf="originalChecked" class="row" [formGroup]="originalGroup">
			<legend aria-label="Empfänger wählen"></legend>
			<div class="column-sub" data-test-id="empfaenger-original">
				<mat-checkbox color="primary" class="focus-box-shadow-dark" data-test-id="aufgabe-messbrief-original-checkbox-eigentuemer"
							  [formControlName]="formServiceClass.feldMessbriefOriginalAnEigentuemer">
					Eigentümer
				</mat-checkbox>
				<mat-checkbox color="primary" class="focus-box-shadow-dark" data-test-id="aufgabe-messbrief-original-checkbox-vertreter"
							  [formControlName]="formServiceClass.feldMessbriefOriginalAnVertreter">
					Vertreter
				</mat-checkbox>
			</div>
		</fieldset>
		<div class="row">
			<mat-checkbox color="primary" class="focus-box-shadow-dark" data-test-id="aufgabe-messbrief-checkbox-vorabkopie"
						  [formControlName]="formServiceClass.feldMessbriefVorabKopie">
				vorab begl. Kopie
			</mat-checkbox>
		</div>
		<div class="row">
			<mat-checkbox color="primary" class="focus-box-shadow-dark" data-test-id="aufgabe-messbrief-checkbox-zda"
						  [formControlName]="formServiceClass.feldMessbriefZdA">
				Kopie z. d. A.
			</mat-checkbox>
		</div>
		<div class="row">
			<mat-checkbox color="primary" class="focus-box-shadow-dark" data-test-id="aufgabe-messbrief-checkbox-ergaenzen"
						  [formControlName]="formServiceClass.feldMessbriefErgaenzen">
				Messbrief bzw. Eichschein / Schiffszeugnis ergänzen
			</mat-checkbox>
		</div>
		<div class="row, additionalline" *ngIf="ergaenzenChecked">
			    (bei Löschung: U-Signal röten bzw. Eintragungsvermerk röten) + senden an
		</div>
		<fieldset *ngIf="ergaenzenChecked" class="row" [formGroup]="ergaenzenGroup">
			<legend aria-label="Empfänger wählen"></legend>
			<div class="column-sub" data-test-id="empfaenger-ergaenzen">
				<mat-checkbox color="primary" class="focus-box-shadow-dark" data-test-id="aufgabe-messbrief-ergaenzen-checkbox-bsh"
							  [formControlName]="formServiceClass.feldMessbriefErgaenzenAnBSH">
					BSH
				</mat-checkbox>
				<mat-checkbox color="primary" class="focus-box-shadow-dark" data-test-id="aufgabe-messbrief-original-checkbox-einreicher"
							  [formControlName]="formServiceClass.feldMessbriefErgaenzenAnEinreicher">
					Einreicher
				</mat-checkbox>
			</div>
		</fieldset>
	</fieldset>
	<schir-int-client-notiz-in-aufgabe-editor class="row note" [aufgabenTyp]="aufgabenTyp">
	</schir-int-client-notiz-in-aufgabe-editor>
</fieldset>
