import {Injectable} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators} from '@angular/forms';
import {CreateVorlage, VorlageFacade} from '@schir-int-client/vorlage-shared';


export function fileExtensionValidator(allowedExtensions: string[]): ValidatorFn {
	return (control: AbstractControl): { [key: string]: any } | null => {
		const file: File = control.value;
		if (file) {
			const extension = file.name.split('.').pop();
			if (!allowedExtensions.includes(extension.toLowerCase())) {
				return { 'invalidExtension': { value: control.value } };
			}
		}
		return null;
	};
}

@Injectable()
export class VorlageFormService {
	public form: UntypedFormGroup;

	constructor(private formBuilder: UntypedFormBuilder, private facade: VorlageFacade) {
		this.initForm();
	}

	private initForm(): void {
		this.form = this.formBuilder.group({
			name: [null, Validators.required],
			file: [null, [Validators.required, fileExtensionValidator(['csv', 'docx'])]],
		});
	}

	public submit(): boolean {
		if (this.form.valid) {
			this.facade.saveVorlage(this.createVorlage());
			return true;
		}
		return false;
	}

	private createVorlage(): CreateVorlage {
		return <CreateVorlage>{ ...this.form.value, fileId: null };
	}

	public updateFile(file: File) {
		this.form.controls['file'].patchValue(file);
	}
}
