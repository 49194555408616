import {AfterContentInit, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Actions} from '@ngrx/effects';
import {
	VerfuegungActions,
	VerfuegungFacade, VerfuegungLinkRel,
	VerfuegungResource,
	VerfuegungTyp,
} from '@schir-int-client/verfuegung-shared';
import {
	VorgangResource,
	VorgangTypLabel,
	VorgangTypLabelLFPR,
	VorgangTypLabelSchiff,
} from '@schir-int-client/vorgang-shared';
import {DialogService, HandlesBackdropClickAndEscapeKey, Spinner} from '@schir-int-client/tech';
import {Observable} from 'rxjs';
import {CreateVerfuegungDialogComponent} from './create-verfuegung-dialog/create-verfuegung-dialog.component';
import {map} from 'rxjs/operators';
import {CreateKorrespondenzVerfuegungDialogComponent} from './create-korrespondenz-verfuegung-dialog/create-korrespondenz-verfuegung-dialog.component';
import {ApiRootFacade} from '@schir-int-client/api-root';
import {CreateLfprVerfuegungDialogComponent} from './create-lfpr-verfuegung-dialog/create-lfpr-verfuegung-dialog.component';
import {ComponentType} from 'ngx-toastr';

@Component({
	selector: 'schir-int-client-verfuegungen-in-vorgang',
	templateUrl: './verfuegungen-in-vorgang.component.html',
	styleUrls: ['./verfuegungen-in-vorgang.component.scss'],
})
export class VerfuegungenInVorgangComponent
	extends HandlesBackdropClickAndEscapeKey<CreateVerfuegungDialogComponent | CreateKorrespondenzVerfuegungDialogComponent>
	implements OnInit, OnChanges, AfterContentInit, OnDestroy {

	@Input() vorgang: VorgangResource;
	@ViewChild('spinner', { static: true }) spinnerRef: ElementRef;
	@ViewChild('button', { static: true, read: ElementRef }) createVerfgBtn: ElementRef;

	verfuegungenInVorgang: Observable<VerfuegungResource[]>;
	korrespondenzVerfuegungenInVorgang: Observable<VerfuegungResource[]>;
	vorgangsTypen: VorgangTypLabel;


	private spinner: Spinner;

	constructor(private verfuegungFacade: VerfuegungFacade,
	            private actions: Actions,
	            protected dialogService: DialogService,
	            private apiRootFacade: ApiRootFacade) {
		super(dialogService);

	}

	ngOnInit(): void {
		this.vorgangsTypen = this.apiRootFacade.isLFPR ? VorgangTypLabelLFPR : VorgangTypLabelSchiff;
	}

	ngOnChanges() {
		this.verfuegungenInVorgang = this.verfuegungFacade.verfuegungenByVorgang.pipe(
			map(verfuegungen => verfuegungen?.filter(verfuegung => verfuegung.typ == VerfuegungTyp.STANDARD)),
		);
		this.korrespondenzVerfuegungenInVorgang = this.verfuegungFacade.verfuegungenByVorgang.pipe(
			map(verfuegungen => verfuegungen?.filter(verfuegung => verfuegung.typ == VerfuegungTyp.KORRESPONDENZ)),
		);
	}

	ngAfterContentInit() {
		this.spinner = new Spinner(this.actions, VerfuegungActions.LOAD_VERFUEGUNGEN_BY_VORGANG, VerfuegungActions.LOAD_VERFUEGUNGEN_BY_VORGANG_SUCCESS, this.spinnerRef);
	}

	ngOnDestroy() {
		this.spinner.destroy();
	}

	openCreateVerfuegungDialog() {
		const editorType: ComponentType<any> = this.apiRootFacade.isLFPR ? CreateLfprVerfuegungDialogComponent : CreateVerfuegungDialogComponent;
		this.dialogService.openEditorDialog(this, editorType);
	}

	openCreateKorrespondenzVerfuegungDialog() {
		this.dialogService.openEditorDialog(this, CreateKorrespondenzVerfuegungDialogComponent);
	}

	protected readonly linkRel = VerfuegungLinkRel;
}
