import {RegisterName} from '@schir-int-client/register-shared';

export enum VerfahrenMessages {
	ASK_TO_REJECT_VERFAHREN = 'Soll das Verfahren abgelehnt werden?',
	ASK_TO_DELETE_VERFAHREN = 'Wollen Sie dieses Verfahren wirklich löschen?',
	ASK_TO_UNASSIGN_KONTAKT = 'Wollen Sie den letzten Kontakt wirklich aus diesem Verfahren löschen? Der Kontakt bleibt für andere Verfahren verfügbar.',
	CONFIRM_CREATE_NEUEINTRAGUNG = 'Soll der Posteingang als Neueintragung übernommen werden?',
	CONFIRM_CREATE_ALLGEMEINES_REGISTER = 'Soll der Posteingang in das Allgemeine Register übernommen werden?',
	ACKNOWLEDGE_VERFAHREN_CREATED = 'Der Vorgang wurde erfolgreich angelegt.',
	CONFIRM_ASSIGN_USIGNAL = 'Wollen Sie dem Verfahren {aktenzeichen} ein freies U-Signal zuordnen?'
}

export function getConfirmationMessageByRegister(registerName: RegisterName) {
	return (registerName === 'AR') ?
		VerfahrenMessages.CONFIRM_CREATE_ALLGEMEINES_REGISTER :
		VerfahrenMessages.CONFIRM_CREATE_NEUEINTRAGUNG;
}

export enum VerfahrenStatusLabel {
	VORLAEUFIG = 'Vorläufig',
	EINGETRAGEN = 'Eingetragen',
	GELOESCHT = 'Gelöscht',
	GESCHLOSSEN = 'Geschlossen',
	ABGELEHNT = 'Abgelehnt'
}

export enum ZaehlblattAktionTypLabel {
	EIGENTUEMER = 'Eigentümer',
	LFZ_NEU = 'LFZ Neu',
	LOESCHUNG_PFANDRECHT = 'Löschung Pfandrecht',
	PFANDRECHT_NEU = 'Pfandrecht neu',
	PFANDRECHTE_AN_BESTEHENDEN_LFZ = 'Pfandrechte an bestehenden LFZ',
	SCHLIESSUNG_DES_BLATTES = 'Schließung des Blattes',
	EINGEREICHTE_URKUNDE_ZUM_REGISTER = 'eingereichte Urkunde zum Register',
	SONSTIGE = 'Sonstige Handlungen und Entscheidungen außerhalb eines anhängigen Verfahrens',
	EINGEREICHTE_URKUNDEN_SSR = 'eingereichte Urkunden - SSR',
	EINGEREICHTE_URKUNDEN_BSR = 'eingereichte Urkunden - BSR',
	EINGEREICHTE_URKUNDEN_SBR = 'eingereichte Urkunden - SBR',
	EINGEREICHTE_URKUNDEN_AR = 'eingereichte Urkunden - AR',
}

export enum ZaehlblattAktionTypShortLabel {
	EIGENTUEMER = 'Eigentümer',
	LFZ_NEU = 'LFZ Neu',
	LOESCHUNG_PFANDRECHT = 'Löschung Pfandrecht',
	PFANDRECHT_NEU = 'Pfandrecht neu',
	PFANDRECHTE_AN_BESTEHENDEN_LFZ = 'Pfandrechte an best. LFZ',
	SCHLIESSUNG_DES_BLATTES = 'Schließung des Blattes',
	EINGEREICHTE_URKUNDE_ZUM_REGISTER = 'einger. Urkunde zum Register',
	SONSTIGE = 'Sonstige außerhalb Verf.',
	EINGEREICHTE_URKUNDEN_SSR = 'eingereichte Urkunden - SSR',
	EINGEREICHTE_URKUNDEN_BSR = 'eingereichte Urkunden - BSR',
	EINGEREICHTE_URKUNDEN_SBR = 'eingereichte Urkunden - SBR',
	EINGEREICHTE_URKUNDEN_AR = 'eingereichte Urkunden - AR',
}

export enum VerfahrenSearchModeCommonLabel {
	'common.aktenzeichen' = 'Aktenzeichen',
	'common.short_aktenzeichen' = 'Aktenzeichen (ohne Abteilungsnummer)',
	'common.aufschrift' = 'Aufschrift',
	'common.blattnummer' = 'Blattnummer',
	'common.eigentuemer_namen' = 'Eigentümer',
	'common.register' = 'Register',

	'common.unsigned_aufschrift' = 'Aufschrift (unsigniert)',
	'common.unsigned_eigentuemer_namen' = 'Eigentümer (unsigniert)',
}

export enum VerfahrenSearchModeSchirLabel {
	'schiff.gattung' = 'Gattung',
	'schiff.hauptbaustoff' = 'Hauptbaustoff',
	'schiff.historische_schiff_namen' = 'Historische Schiffsnamen',
	'schiff.imo_nummer' = 'IMO-Nrn.',
	'schiff.schiff_name' = 'Schiffsnamen',
	'schiff.unterscheidungssignal' = 'U-Signale',

	'schiff.unsigned_gattung' = 'Gattung (unsigniert)',
	'schiff.unsigned_hauptbaustoff' = 'Hauptbaustoff (unsigniert)',
	'schiff.unsigned_imo_nummer' = 'IMO-Nrn. (unsigniert)',
	'schiff.unsigned_schiff_name' = 'Schiffsnamen (unsigniert)',
	'schiff.unsigned_unterscheidungssignal' = 'U-Signale (unsigniert)',
}

export enum VerfahrenSearchModeLfprLabel {
	'lfpr.eintragungszeichen' = 'Eintragungszeichen',
	'lfpr.unsigned_einttagungszeichen' = 'Eintragungszeichen (unsigniert)',
	'lfpr.glaeubiger' = 'Gläubiger',
	'lfpr.blattnummer' = 'Blattnummer der Luftfahrzeugrolle',
	'lfpr.muster' = 'Muster',
	'lfpr.werknummer' = 'Werknummer',
	'lfpr.standort_akte' = 'Standort der Akte',
	'lfpr.ar_kennzeichen' = 'Kennzeichen (AR)',
}

export const VerfahrenSearchModeLabel = {
	...VerfahrenSearchModeCommonLabel,
	...VerfahrenSearchModeSchirLabel,
	...VerfahrenSearchModeLfprLabel,
};

export type VerfahrenSearchKey = string;
export type VerfahrenSearchModeLabel = typeof VerfahrenSearchModeLabel;

export enum VerfahrenSearchFieldPlaceholderLabel {
	ALL = 'Aktenzeichen, Schiffsnamen, IMO-Nummern, Eigentümer, U-Signale',
	AKTENZEICHEN = 'Aktenzeichen',
	SCHIFFSNAMEN = 'Schiffsnamen',
	IMO_NUMMER = 'IMO-Nummern',
	EIGENTUEMER = 'Eigentümer',
	U_SIGNALE = 'U-Signale',
	HISTORISCHE_SCHIFFSNAMEN = 'Historische Schiffsnamen',
	GELOESCHT = 'Gelöschte Verfahren'
}
