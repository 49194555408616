import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {
	AdressverwaltungFacade,
	AdressverwaltungRootState,
	AdressverwaltungService,
	ASK_SWITCH_UNSAVED_KONTAKT_IN_EDIT,
	KontaktKategorie,
	KontaktKategorieLabel, KontaktLinkRel,
	KontaktResource,
	KontaktSearchMode,
	KontaktWithKategorie,
} from '@schir-int-client/adressverwaltung-shared';
import {Adressat, AdressatDragData, AdressatFacade} from '@schir-int-client/aufgabe-shared';
import {getUrl, hasLink} from '@ngxp/rest';
import {VerfahrenLinkRel, VerfahrenResource} from '@schir-int-client/verfahren-shared';
import {
	ClipboardService,
	DialogService,
	HandlesBackdropClickAndEscapeKey,
	KontaktAktenzeichenDialogComponent,
	NotizDialogComponent,
} from '@schir-int-client/tech';
import {Store} from '@ngrx/store';
import {Actions} from '@ngrx/effects';
import {Subject, Subscription} from 'rxjs';
import {isNil} from 'lodash-es';
import {AdressverwaltungDialogComponent} from '../../adressverwaltung-dialog/adressverwaltung-dialog.component';
import {first, take} from 'rxjs/operators';
import {MailAddressService} from '../../../../../../tech/src/lib/mail-address/mail-address.service';

@Component({
	selector: 'schir-int-client-kontakt-in-kategorie',
	templateUrl: './kontakt-in-kategorie.component.html',
	styleUrls: ['./kontakt-in-kategorie.component.scss'],
})
export class KontaktInKategorieComponent
	extends HandlesBackdropClickAndEscapeKey<AdressverwaltungDialogComponent | NotizDialogComponent | KontaktAktenzeichenDialogComponent>
	implements OnInit, OnDestroy {

	@Input() kontakt: KontaktResource;
	@Input() kategorie: KontaktKategorie;
	@Input() draggable: boolean;
	@Input() unassignable: boolean;
	@Input() verfahren: VerfahrenResource;

	@Output() unassignKontakt: EventEmitter<KontaktWithKategorie> = new EventEmitter();

	expand: boolean;
	showAssignButton: boolean;
	kategorieLabel = KontaktKategorieLabel;

	private subscriptions: Subscription[] = [];
	audioAlert: Subject<string> = new Subject<string>();

	constructor(protected dialogService: DialogService,
	            private store: Store<AdressverwaltungRootState>,
	            private adressatFacade: AdressatFacade,
	            private actions: Actions,
	            private adressverwaltungService: AdressverwaltungService,
	            private adressverwaltungFacade: AdressverwaltungFacade,
	            private clipboardService: ClipboardService,
	            private mailAddressService: MailAddressService) {
		super(dialogService);

		this.subscriptions.push(this.adressverwaltungService.adressatenColDisplayed.subscribe(display => {
			this.showAssignButton = display && this.kontakt.active && hasLink(this.kontakt, KontaktLinkRel.EDIT); //TODO: feuert nicht nach Update eines Kontakts
		}));
	}

	ngOnInit() {
		this.draggable = this.kontakt.active;
	}

	ngOnDestroy() {
		this.subscriptions.forEach(s => s.unsubscribe());
	}

	drag(event: DragEvent, kontakt: KontaktResource) {
		const dragData: AdressatDragData = {
			uri: getUrl(kontakt),
			name: kontakt.name,
			vorname: kontakt.vorname,
			firmenName: kontakt.firmenName,
			kategorie: this.kategorie,
		};
		const data = JSON.stringify(dragData);
		event.dataTransfer.setData('text/plain', data);
	}

	get hasUnassignLinkForKategorie(): boolean {
		return hasLink(this.verfahren, VerfahrenLinkRel.UNASSIGN_KONTAKT_IN_KATEGORIE) && this.unassignable;
	}

	unassign(): void {
		this.unassignKontakt.emit({ kontakt: this.kontakt, kategorie: this.kategorie });
		this.audioAlert.next('Der Kontakt wurde aus dem Verfahren entfernt.');
	}

	openKontaktInAdressverwaltung(kontakt: KontaktResource) {
		this.matDialogRef = this.dialogService.openEditorDialog(this, AdressverwaltungDialogComponent, {
			panelClass: 'adressverwaltung',
		});

		const editor: AdressverwaltungDialogComponent = <AdressverwaltungDialogComponent>this.matDialogRef.componentInstance;

		let searchString: string;
		if (this.kontakt.juristischePerson) {
			searchString = this.kontakt.firmenName + ' ' + this.kontakt.vorname + ' ' + this.kontakt.name;
		} else {
			searchString = this.kontakt.vorname + ' ' + this.kontakt.name;
		}

		if (this.kontakt.active) {
			editor.searchParameters = {
				searchString: searchString,
				searchModes: [KontaktSearchMode.NATUERLICH, KontaktSearchMode.JURISTISCH, KontaktSearchMode.BEHOERDEN, KontaktSearchMode.AKTIV],
			};
		} else {
			editor.searchParameters = {
				searchString: searchString,
				searchModes: [KontaktSearchMode.NATUERLICH, KontaktSearchMode.JURISTISCH, KontaktSearchMode.BEHOERDEN, KontaktSearchMode.INAKTIV],
			};
		}

		this.adressverwaltungFacade.setSelectedKontakt(kontakt);
		editor.setSelectedKontakt({ kontakt: kontakt, editMode: false });
		this.adressverwaltungFacade.setEditMode(this.kontakt.active);
	}

	protected closeAndSetActiveEditorNull() {
		this.subscriptions.push(this.adressverwaltungFacade.selectedKontakt.pipe(first()).subscribe(kontakt => {
			this.adressverwaltungFacade.setEditMode(false);
			this.matDialogRef.close();
		}));
	}

	assignKontakt() {
		const adressat: Adressat = {
			channelBrief: true,
			channelFax: false,
			channelEmail: false,
			channelAbholung: false,
			channelEgvp: false,
			kategorie: this.kategorie,
			kontakt: this.kontakt._links.self.href,
			notiz: '',
		};
		this.adressatFacade.createAdressat(adressat);
		this.audioAlert.next('Der Kontakt wurde als Adressat hinzugefügt.');
	}

	openVerfahrenKontaktNotizDialog() {
		const name = this.kontakt.juristischePerson ? this.kontakt.firmenName : this.kontakt.name;

		const title = 'Notiz zum Kontakt "' + name + '"';

		this.matDialogRef = this.dialogService.openEditorDialog(this, NotizDialogComponent, {
			data: {
				title: title,
				notiz: this.kontakt.verfahrenKontaktNotiz,
			},
		});

		const editor: NotizDialogComponent = <NotizDialogComponent>this.matDialogRef.componentInstance;
		this.subscriptions.push(editor.notizEvent.pipe(take(1)).subscribe((data: string) => {
			this.updateVerfahrenKontaktNotiz(data);
		}));
	}

	openKontaktAktenzeichenDialog() {
		const name = this.kontakt.juristischePerson ? this.kontakt.firmenName : this.kontakt.name;

		const title = 'Aktenzeichen für Kontakt "' + name + '"';

		this.matDialogRef = this.dialogService.openEditorDialog(this, KontaktAktenzeichenDialogComponent, {
			data: {
				title: title,
				kontaktAktenzeichen: this.kontakt.verfahrenKontaktAktenzeichen,
			},
		});

		const editor: KontaktAktenzeichenDialogComponent = <KontaktAktenzeichenDialogComponent>this.matDialogRef.componentInstance;
		this.subscriptions.push(editor.kontaktAktenzeichenEvent.pipe(take(1)).subscribe((data: string) => {
			this.updateVerfahrenKontaktAktenzeichen(data);
		}));
	}

	updateVerfahrenKontaktNotiz(notiz: string) {
		const updatedKontakt: KontaktWithKategorie = {
			kontakt: {
				...this.kontakt,
				verfahrenKontaktNotiz: notiz,
			},
			kategorie: this.kategorie,
		};

		this.adressverwaltungFacade.updateVerfahrenKontaktNotiz(updatedKontakt);
	}

	updateVerfahrenKontaktAktenzeichen(aktenzeichen: string) {
		const updatedKontakt: KontaktWithKategorie = {
			kontakt: {
				...this.kontakt,
				verfahrenKontaktAktenzeichen: aktenzeichen,
			},
			kategorie: this.kategorie,
		};

		this.adressverwaltungFacade.updateVerfahrenKontaktAktenzeichen(updatedKontakt);
	}

	openFinanzSanktionsListe() {
		let parameters: string[];

		if (this.kontakt.juristischePerson) {
			parameters = [this.kontakt.firmenName];
		} else {
			parameters = [this.kontakt.name, this.kontakt.vorname];
		}

		const searchQuery = parameters.filter(x => !isNil(x)).join(' ');

		window.open('https://www.finanz-sanktionsliste.de/fisalis/?txtSearch=' + searchQuery + '&cmdSearch=%F0%9F%94%8D', '_blank');
	}

	getConfirmMessage(): string {
		return ASK_SWITCH_UNSAVED_KONTAKT_IN_EDIT;
	}

	copyMailAddressToClipboard(kontakt: KontaktResource) {
		const mailAddress = this.mailAddressService.createMailAddressForKontakt(kontakt);
		this.clipboardService.copyAndShowSnackBar(mailAddress, 'Adressdaten wurden in die Zwischenablage kopiert.');
	}

	protected readonly KontaktLinkRel = KontaktLinkRel;
}
