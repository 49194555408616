import {Component, Input, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {getUrl, hasLink, ResourceUri} from '@ngxp/rest';
import {
	VerfahrenFacade,
	VerfahrenLinkRel,
	VerfahrenMessages,
	VerfahrenResource,
} from '@schir-int-client/verfahren-shared';
import {AmtlichenAusdruckErstellenDialogComponent} from '@schir-int-client/alternative-text';
import {DialogService, HandlesBackdropClickAndEscapeKey, NotizDialogComponent} from '@schir-int-client/tech';
import {
	AktenzeichenRenderer,
	AktenzeichenRendererProvider,
	AktenzeichenUtilService,
} from '@schir-int-client/aktenzeichen-shared';
import {Subscription} from 'rxjs';
import {StandortAkteComponent} from '../standort-akte/standort-akte.component';
import {take} from 'rxjs/operators';
import {ZaehlblattErfassenComponent} from '../zaehlblatt/zaehlblatt-erfassen.component';
import {ZaehlblattAnzeigenComponent} from '../zaehlblatt/zaehlblatt-anzeigen.component';
import {KennzeichenComponent} from '../../kennzeichen/kennzeichen.component';

@Component({
	selector: 'schir-int-client-button-with-menu',
	templateUrl: './button-with-menu.component.html',
	styleUrls: ['./button-with-menu.component.scss'],
})
export class ButtonWithMenuComponent
	extends HandlesBackdropClickAndEscapeKey<NotizDialogComponent | AmtlichenAusdruckErstellenDialogComponent | StandortAkteComponent | ZaehlblattErfassenComponent | ZaehlblattAnzeigenComponent | KennzeichenComponent>
	implements OnDestroy {

	private renderer: AktenzeichenRenderer;
	@Input() verfahren: VerfahrenResource;

	private subscriptions: Subscription[] = [];

	readonly linkRel = VerfahrenLinkRel;

	constructor(private facade: VerfahrenFacade,
	            private router: Router,
	            protected dialogService: DialogService,
	            private rendererProvider: AktenzeichenRendererProvider,
	            private aktenzeichenUtil: AktenzeichenUtilService) {
		super(dialogService);

		this.renderer = this.rendererProvider.getRenderer();
	}

	get verfahrenUri() {
		return btoa(getUrl(this.verfahren));
	}

	private renderAktenzeichen() {
		const aktenzeichen = this.aktenzeichenUtil.parse(this.verfahren);
		return this.renderer.render(aktenzeichen);
	}

	openNotizDialog(event: MouseEvent) {
		this.preventMenuClosingForKeyboard(event);

		const title = 'Notizen zum Verfahren ' + this.renderAktenzeichen();

		this.matDialogRef = this.dialogService.openEditorDialog(this, NotizDialogComponent, {
			data: {
				title,
				notiz: this.verfahren.notiz,
				verfahren: this.verfahren,
				readonly: !hasLink(this.verfahren, VerfahrenLinkRel.NOTIZ),
			},
		});

		const editor = <NotizDialogComponent>this.matDialogRef.componentInstance;
		this.subscriptions.push(editor.notizEvent.pipe(take(1)).subscribe((data: string) => {
			this.updateNotiz(data);
		}));
	}

	updateNotiz(notiz: string) {
		this.facade.updateNotiz(notiz, getUrl(this.verfahren, VerfahrenLinkRel.NOTIZ));
	}

	hasRegisterBlatt(): boolean {
		return hasLink(this.verfahren, VerfahrenLinkRel.BINNENSCHIFFSREGISTER) ||
			hasLink(this.verfahren, VerfahrenLinkRel.SEESCHIFFSREGISTER) ||
			hasLink(this.verfahren, VerfahrenLinkRel.SCHIFFSBAUREGISTER) ||
			hasLink(this.verfahren, VerfahrenLinkRel.LUFTFAHRTPFANDRECHTSREGISTER);
	}

	openRegisterBlatt() {
		const verfahrenUri: ResourceUri = this.verfahrenUri.replace('/', '_');
		this.router.navigate(['/verfahrenDetail/' + verfahrenUri]);
	}

	openAmtlichenAuszugErstellenDialog() {
		this.dialogService.openEditorDialog(this, AmtlichenAusdruckErstellenDialogComponent);
	}

	downloadSchiffszertifikat() {
		this.facade.downloadSchiffszertifikat();
	}

	downloadSchiffsbrief() {
		this.facade.downloadSchiffsbrief();
	}

	markAsAbgelehnt(event: MouseEvent) {
		this.preventMenuClosingForKeyboard(event);
		this.facade.markAsAbgelehnt(this.verfahren);
	}

	openUsignalZuordnenDialog() {
		const aktenzeichen = this.aktenzeichenUtil.parse(this.verfahren.aktenzeichen);

		this.subscriptions.push(this.dialogService.openDialogYesNo(VerfahrenMessages.CONFIRM_ASSIGN_USIGNAL
			.replace('{aktenzeichen}', this.renderer.render(aktenzeichen)))
			.pipe(take(1)).subscribe(data => {
				if (data.agree) {
					this.facade.assignUSignal(this.verfahren);
				}
			}));
	}

	private preventMenuClosingForKeyboard(event: MouseEvent) {
		if (event.detail === 0) {
			event.stopPropagation();
		}
	}

	openStandortAkteDialog() {
		this.dialogService.openEditorDialog(this, StandortAkteComponent, this.getConfig());
	}

	openZaehlblattErfassenDialog() {
		this.dialogService.openEditorDialog(this, ZaehlblattErfassenComponent, this.getConfig());
	}

	openZaehlblattAnzeigenDialog() {
		this.dialogService.openEditorDialog(this, ZaehlblattAnzeigenComponent, this.getConfig());
	}

	openKennzeichenDialog() {
		this.dialogService.openEditorDialog(this, KennzeichenComponent, this.getConfig());
	}

	private getConfig() {
		return {
			data: {
				verfahren: this.verfahren,
				aktenzeichen: this.renderAktenzeichen(),
			},
		};
	}

	ngOnDestroy() {
		this.subscriptions.forEach(s => s.unsubscribe());
	}
}
