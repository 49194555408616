import {Injectable} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {VorgangFacade, VorgangResource, VorgangService, VorgangStatus} from '@schir-int-client/vorgang-shared';
import {first} from 'rxjs/operators';
import {isNil} from 'lodash-es';

@Injectable()
export class ChangeVorgangStatusFormservice {

	readonly wiedervorlageDatumControlName = 'wiedervorlageDatum';
	readonly statusControlName = 'status';

	private vorgang: VorgangResource;

	form: UntypedFormGroup;

	showDatumsHinweis: boolean;

	constructor(private formBuilder: UntypedFormBuilder,
	            private vorgangFacade: VorgangFacade,
	            private vorgangService: VorgangService) {
		this.form = this.createForm();
	}

	private createForm(): UntypedFormGroup {
		return this.formBuilder.group({
			[this.statusControlName]: new UntypedFormControl(null, Validators.required),
			[this.wiedervorlageDatumControlName]: new UntypedFormControl(this.createDefaultDate(), [Validators.required]),
		});
	}

	public getSelectedStatus() {
		return this.form.controls[this.statusControlName].value;
	}

	public patchValue(vorgang: VorgangResource): void {
		this.vorgang = vorgang;
		this.vorgangFacade.setSelectedVorgang(this.vorgang);

		this.form.controls[this.statusControlName].patchValue(this.vorgang.status);

		const datumControl: AbstractControl = this.form.controls[this.wiedervorlageDatumControlName];
		const selectedVorgang = this.vorgangFacade.getSelectedVorgang();

		selectedVorgang.pipe(first()).subscribe(vorgang => {
			if (vorgang.wiedervorlageDatum) {
				datumControl.setValue(vorgang.wiedervorlageDatum);
			} else {
				this.vorgangService.getProposedWiederVorlageDatum(vorgang).pipe(first()).subscribe(date => {
					datumControl.setValue(date.date);
					this.showDatumsHinweis = date.multiple;
				});
			}
		});
	}

	private createDefaultDate(): Date {
		const date = new Date();
		date.setDate(new Date().getDate() + (8 * 7)); // + 2 Monate
		return date;
	}

	isValid(): boolean {
		if (this.getSelectedStatus() == VorgangStatus.WIEDERVORLAGE) {
			return this.form.valid;
		}

		return !isNil(this.getSelectedStatus()) && (this.getSelectedStatus() != VorgangStatus.NEU);
	}

	async submit(): Promise<boolean> {
		if (!this.form.valid) {
			return false;
		}

		const selectedStatus = this.getSelectedStatus();

		switch (selectedStatus) {
			case VorgangStatus.RECHTSPFLEGE: {
				this.vorgangFacade.markAsRechtspflege(this.vorgang);
				break;
			}
			case VorgangStatus.RUECKLAUF: {
				this.vorgangFacade.markAsRuecklauf(this.vorgang);
				break;
			}
			case VorgangStatus.ABGESCHLOSSEN: {
				this.vorgangFacade.markAsAbgeschlossen(this.vorgang);
				break;
			}
			case VorgangStatus.WIEDERVORLAGE: {
				this.vorgangFacade.markAsWiedervorlage(this.vorgang, this.form.controls[this.wiedervorlageDatumControlName].value);
			}
		}

		return true;
	}

}
