import {Injectable, OnDestroy} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {AufgabenTyp} from '@schir-int-client/aufgabe-shared';
import {VerfuegungFacade} from '@schir-int-client/verfuegung-shared';
import {CreateAufgabeFormService} from '@schir-int-client/aufgabe';
import {isNil} from 'lodash-es';
import {Subscription} from 'rxjs';
import {filter, take} from 'rxjs/operators';

@Injectable()
export class CreateVerfuegungFormService implements OnDestroy {

	readonly controlAufgabeFinanzsanktionsliste = new UntypedFormControl();
	readonly controlAufgabeZertifikat = new UntypedFormControl();
	readonly controlAufgabeAltesZertifikat = new UntypedFormControl();
	readonly controlAufgabeAlterAuszug = new UntypedFormControl();
	readonly controlAufgabeSonstige = new UntypedFormControl();
	readonly controlAufgabeAblage = new UntypedFormControl();
	readonly controlAufgabeZertifikatsauszug = new UntypedFormControl();
	readonly controlAufgabeAbschriftZertifikatsauszug = new UntypedFormControl();
	readonly controlAufgabeHypothekenbescheinigung = new UntypedFormControl();
	readonly controlAufgabeEigentuemerbescheinigung = new UntypedFormControl();
	readonly controlAufgabeLoeschungsbescheinigung = new UntypedFormControl();
	readonly controlAufgabeLastenfreiheitsbescheinigung = new UntypedFormControl();
	readonly controlAufgabeNamensaenderungsbescheinigung = new UntypedFormControl();
	readonly controlAufgabeRegisterabschrift = new UntypedFormControl();
	readonly controlAufgabeWiedervorlage = new UntypedFormControl();
	readonly controlAufgabeEintragungsmitteilung = new UntypedFormControl();
	readonly controlAufgabeMessbrief = new UntypedFormControl();
	readonly controlAufgabeRechnungsstellung = new UntypedFormControl();
	readonly controlAufgabeKosten = new UntypedFormControl();
	readonly controlVerfuegungNotiz = new UntypedFormControl();

	form: UntypedFormGroup = new UntypedFormGroup({
		controlAufgabeZertifikat: this.controlAufgabeZertifikat,
		controlAufgabeAltesZertifikat: this.controlAufgabeAltesZertifikat,
		controlAufgabeAlterAuszug: this.controlAufgabeAlterAuszug,
		controlAufgabeSonstige: this.controlAufgabeSonstige,
		controlAufgabeAblage: this.controlAufgabeAblage,
		controlAufgabeZertifikatsauszug: this.controlAufgabeZertifikatsauszug,
		controlAufgabeAbschriftZertifikatsauszug: this.controlAufgabeAbschriftZertifikatsauszug,
		controlAufgabeHypothekenbescheinigung: this.controlAufgabeHypothekenbescheinigung,
		controlAufgabeEigentuemerbescheinigung: this.controlAufgabeEigentuemerbescheinigung,
		controlAufgabeLoeschungsbescheinigung: this.controlAufgabeLoeschungsbescheinigung,
		controlAufgabeLastenfreiheitsbescheinigung: this.controlAufgabeLastenfreiheitsbescheinigung,
		controlAufgabeNamensaenderungsbescheinigung: this.controlAufgabeNamensaenderungsbescheinigung,
		controlAufgabeRegisterabschrift: this.controlAufgabeRegisterabschrift,
		controlAufgabeWiedervorlage: this.controlAufgabeWiedervorlage,
		controlAufgabeEintragungsmitteilung: this.controlAufgabeEintragungsmitteilung,
		controlAufgabeMessbrief: this.controlAufgabeMessbrief,
		controlAufgabeRechnungsstellung: this.controlAufgabeRechnungsstellung,
		controlAufgabeKosten: this.controlAufgabeKosten,
		controlVerfuegungNotiz: this.controlVerfuegungNotiz,
	});

	private typeControlsSubscriptions: Subscription;

	constructor(private facade: VerfuegungFacade, private aufgabeFormService: CreateAufgabeFormService) {
		this.typeControlsSubscriptions = this.buildTypeControlSubscription(this.controlAufgabeFinanzsanktionsliste, AufgabenTyp.FINANZSANKTIONSLISTE);
		this.typeControlsSubscriptions.add(this.buildTypeControlSubscription(this.controlAufgabeZertifikat, AufgabenTyp.ZERTIFIKAT));
		this.typeControlsSubscriptions.add(this.buildTypeControlSubscription(this.controlAufgabeAltesZertifikat, AufgabenTyp.ALTES_ZERTIFIKAT));
		this.typeControlsSubscriptions.add(this.buildTypeControlSubscription(this.controlAufgabeAlterAuszug, AufgabenTyp.ALTER_AUSZUG));
		this.typeControlsSubscriptions.add(this.buildTypeControlSubscription(this.controlAufgabeSonstige, AufgabenTyp.SONSTIGE));
		this.typeControlsSubscriptions.add(this.buildTypeControlSubscription(this.controlAufgabeAblage, AufgabenTyp.ABLAGE));
		this.typeControlsSubscriptions.add(this.buildTypeControlSubscription(this.controlAufgabeZertifikatsauszug, AufgabenTyp.ZERTIFIKATSAUSZUG));
		this.typeControlsSubscriptions.add(this.buildTypeControlSubscription(this.controlAufgabeAbschriftZertifikatsauszug, AufgabenTyp.ABSCHRIFT_ZERTIFIKATSAUSZUG));
		this.typeControlsSubscriptions.add(this.buildTypeControlSubscription(this.controlAufgabeHypothekenbescheinigung, AufgabenTyp.HYPOTHEKENBESCHEINIGUNG));
		this.typeControlsSubscriptions.add(this.buildTypeControlSubscription(this.controlAufgabeEigentuemerbescheinigung, AufgabenTyp.EIGENTUEMERBESCHEINIGUNG));
		this.typeControlsSubscriptions.add(this.buildTypeControlSubscription(this.controlAufgabeLoeschungsbescheinigung, AufgabenTyp.LOESCHUNGSBESCHEINIGUNG));
		this.typeControlsSubscriptions.add(this.buildTypeControlSubscription(this.controlAufgabeLastenfreiheitsbescheinigung, AufgabenTyp.LASTENFREIHEITSBESCHEINIGUNG));
		this.typeControlsSubscriptions.add(this.buildTypeControlSubscription(this.controlAufgabeNamensaenderungsbescheinigung, AufgabenTyp.NAMENSAENDERUNGSBESCHEINIGUNG));
		this.typeControlsSubscriptions.add(this.buildTypeControlSubscription(this.controlAufgabeRegisterabschrift, AufgabenTyp.REGISTERABSCHRIFT));
		this.typeControlsSubscriptions.add(this.buildTypeControlSubscription(this.controlAufgabeWiedervorlage, AufgabenTyp.WIEDERVORLAGE));
		this.typeControlsSubscriptions.add(this.buildTypeControlSubscription(this.controlAufgabeEintragungsmitteilung, AufgabenTyp.EINTRAGUNGSMITTEILUNG));
		this.typeControlsSubscriptions.add(this.buildTypeControlSubscription(this.controlAufgabeMessbrief, AufgabenTyp.MESSBRIEF));
		this.typeControlsSubscriptions.add(this.buildTypeControlSubscription(this.controlAufgabeRechnungsstellung, AufgabenTyp.RECHNUNGSSTELLUNG));
		this.typeControlsSubscriptions.add(this.buildTypeControlSubscription(this.controlAufgabeKosten, AufgabenTyp.KOSTEN));
	}

	private buildTypeControlSubscription(formControl: UntypedFormControl, aufgabenTyp: AufgabenTyp): Subscription {
		return formControl.valueChanges.subscribe(selected => {
			selected ? this.aufgabeFormService.enableAufgabenTyp(aufgabenTyp) : this.aufgabeFormService.disableAufgabenTyp(aufgabenTyp);
		});
	}

	submit(): boolean {
		if (this.checkPreconditions()) {
			const creating = this.facade.createVerfuegungByVorgang(this.controlVerfuegungNotiz.value);

			if (this.isAnyAufgabeSelected()) {
				creating.pipe(
					filter(created => !created.loading),
					take(1),
				).subscribe(() => this.aufgabeFormService.submit());
			}
			return true;
		}
		return false;
	}

	checkPreconditions(): boolean {
		return !this.isAnyAufgabeSelected() || (this.isAnyAufgabeSelected() && this.aufgabeFormService.isValid());
	}

	private isAnyAufgabeSelected(): boolean {
		return this.controlAufgabeFinanzsanktionsliste.value
			|| this.controlAufgabeZertifikat.value
			|| this.controlAufgabeAltesZertifikat.value
			|| this.controlAufgabeAlterAuszug.value
			|| this.controlAufgabeSonstige.value
			|| this.controlAufgabeAblage.value
			|| this.controlAufgabeZertifikatsauszug.value
			|| this.controlAufgabeAbschriftZertifikatsauszug.value
			|| this.controlAufgabeHypothekenbescheinigung.value
			|| this.controlAufgabeEigentuemerbescheinigung.value
			|| this.controlAufgabeLoeschungsbescheinigung.value
			|| this.controlAufgabeLastenfreiheitsbescheinigung.value
			|| this.controlAufgabeNamensaenderungsbescheinigung.value
			|| this.controlAufgabeRegisterabschrift.value
			|| this.controlAufgabeWiedervorlage.value
			|| this.controlAufgabeEintragungsmitteilung.value
			|| this.controlAufgabeMessbrief.value
			|| this.controlAufgabeRechnungsstellung.value
			|| this.controlAufgabeKosten.value;
	}

	ngOnDestroy(): void {
		if (!isNil(this.typeControlsSubscriptions)) {
			this.typeControlsSubscriptions.unsubscribe();
		}
	}
}
