import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';
import {folgeStatusList, VorgangMessages, VorgangStatus, VorgangStatusLabel} from '@schir-int-client/vorgang-shared';
import {addAriaAttributesToMatSelect, BaseEditorComponent, SCHIR_DATE_FORMATS} from '@schir-int-client/tech';
import {UntypedFormGroup} from '@angular/forms';
import {ChangeVorgangStatusFormservice} from './change-vorgang-status.formservice';
import {MAT_DATE_FORMATS} from '@angular/material/core';

@Component({
	selector: 'schir-int-client-change-vorgang-status-dialog',
	templateUrl: './change-vorgang-status-dialog.component.html',
	styleUrls: ['./change-vorgang-status-dialog.component.scss'],
	providers: [
		ChangeVorgangStatusFormservice,
		{
			provide: MAT_DATE_FORMATS,
			useValue: SCHIR_DATE_FORMATS,
		},
	],
})
export class ChangeVorgangStatusDialogComponent extends BaseEditorComponent implements OnInit {

	vorgangStatus = VorgangStatus;
	vorgangStatusLabel = VorgangStatusLabel;

	protected readonly vorgangMessages = VorgangMessages;
	protected readonly folgeStatusList = folgeStatusList;

	constructor(@Inject(MAT_DIALOG_DATA) data: any, public formService: ChangeVorgangStatusFormservice) {
		super();
		this.formService.patchValue(data.vorgang);
	}

	selectedStatus() {
		return this.formService.getSelectedStatus();
	}

	ngOnInit(): void {
		addAriaAttributesToMatSelect();
	}

	get form(): UntypedFormGroup {
		return this.formService.form;
	}

	isValid(): boolean {
		return this.formService.isValid();
	}

	async submit(): Promise<boolean> {
		return this.formService.submit();
	}
}
