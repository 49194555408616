import {Injectable} from '@angular/core';
import {getUrl, Resource, ResourceFactory, ResourceUri} from '@ngxp/rest';
import {ApiRootLinkRel, ApiRootResource} from '@schir-int-client/api-root';
import {PosteingangLinkRel, PosteingangResource} from '@schir-int-client/posteingang-shared';
import {VerfahrenLinkRel, VerfahrenResource} from '@schir-int-client/verfahren-shared';
import {Observable} from 'rxjs';
import {VorgangLinkRel} from './vorgang.linkrel';
import {CreateVorgang, ProposedDate, VorgangListResource, VorgangResource, VorgangTyp} from './vorgang.model';
import moment, {Moment} from 'moment';
import {isMoment} from 'moment/moment';

@Injectable({ providedIn: 'root' })
export class VorgangService {
	constructor(private resourceFactory: ResourceFactory) { }

	public createVorgangInVerfahren(createVorgangUri: ResourceUri, posteingaenge: ResourceUri[], vorgangType: VorgangTyp): Observable<VorgangListResource> {
		const newVorgang: CreateVorgang = { posteingaenge, typ: vorgangType };
		return this.resourceFactory.fromId(createVorgangUri).post(newVorgang);
	}

	public getAllForRechtspflege(rootResource: Resource): Observable<VorgangListResource> {
		return this.resourceFactory.from(rootResource).get(ApiRootLinkRel.VORGAENGE_RECHTSPFLEGE);
	}

	public getAllInWiedervorlage(apiRoot: ApiRootResource, von: Moment, bis: Moment): Observable<VorgangListResource> {
		const dateFormat = 'YYYY-MM-DD';
		const vonString = isMoment(von) ? von.format(dateFormat) : von;
		const bisString = isMoment(bis) ? bis.format(dateFormat) : bis;

		let url = getUrl(apiRoot, ApiRootLinkRel.VORGAENGE_IN_WIEDERVORLAGE).replace('{wiedervorlageDatumVon}', vonString).replace('{wiedervorlageDatumBis}', bisString);
		return this.resourceFactory.fromId(url).get();
	}

	public getFristenListe(apiRoot: ApiRootResource): Observable<VorgangListResource> {
		return this.resourceFactory.from(apiRoot).get(ApiRootLinkRel.WIEDERVORLAGEN_FRISTEN);
	}

	/** @deprecated prefer using getByLink */
	public getOne(url: string): Observable<VorgangResource> {
		return this.resourceFactory.fromId(url).get();
	}

	public getByLink(resource: Resource, linkRel: string): Observable<VorgangResource> {
		return this.resourceFactory.from(resource).get(linkRel);
	}

	public getZugeordneteVorgaenge(posteingangResource: PosteingangResource): Observable<VorgangListResource> {
		return this.resourceFactory.from(posteingangResource).get(PosteingangLinkRel.ZUGEORDNETE_VORGAENGE);
	}

	public getVorgaengeByVerfahren(verfahren: VerfahrenResource): Observable<VorgangListResource> {
		return this.resourceFactory.from(verfahren).get(VerfahrenLinkRel.VORGAENGE);
	}

	public assignVorgang(zugeordneterVorgang: VorgangResource, rechtspfleger: string): Observable<{}> {
		const rechtspflegerId = rechtspfleger.match(/\/(\d+)$/)[1];
		return this.resourceFactory.from(zugeordneterVorgang).patch(VorgangLinkRel.MARK_AS_RECHTSPFLEGE, rechtspflegerId);
	}

	public loadVorgaengeInRuecklauf(apiRoot: ApiRootResource): Observable<VorgangListResource> {
		return this.resourceFactory.from(apiRoot).get(ApiRootLinkRel.VORGAENGE_IN_RUECKLAUF);
	}

	public loadVorgaengeInWiedervorlage(apiRoot: ApiRootResource): Observable<VorgangListResource> {
		return this.resourceFactory.from(apiRoot).get(ApiRootLinkRel.VORGAENGE_IN_WIEDERVORLAGE);
	}

	public assignVorgangToPosteingang(posteingang: PosteingangResource, vorgang: VorgangResource): Observable<VorgangResource> {
		const posteingangId = getUrl(posteingang).match(/\/(\d+)$/)[1];
		return this.resourceFactory.from(vorgang).post(VorgangLinkRel.ASSIGN_POSTEINGANG, posteingangId);
	}

	public updateNotiz(notiz: string, vorgang: VorgangResource): Observable<VorgangResource> {
		return this.resourceFactory.from(vorgang).put(VorgangLinkRel.UPDATE_NOTIZ, notiz);
	}

	public markAsRechtspflege(vorgang: VorgangResource): Observable<VorgangResource> {
		return this.resourceFactory.from(vorgang).patch(VorgangLinkRel.MARK_AS_RECHTSPFLEGE);
	}

	public markAsRuecklauf(vorgang: VorgangResource): Observable<VorgangResource> {
		return this.resourceFactory.from(vorgang).patch(VorgangLinkRel.MARK_AS_RUECKLAUF);
	}

	public markAsAbgeschlossen(vorgang: VorgangResource): Observable<VorgangResource> {
		return this.resourceFactory.from(vorgang).patch(VorgangLinkRel.MARK_AS_ABGESCHLOSSEN);
	}

	public markAsWiedervorlage(vorgang: VorgangResource, wiedervorlageDatum: Moment): Observable<VorgangResource> {
		return this.resourceFactory.from(vorgang).patch(VorgangLinkRel.MARK_AS_WIEDERVORLAGE, moment.utc(wiedervorlageDatum));
	}

	public delete(vorgang: VorgangResource): Observable<void> {
		return this.resourceFactory.from(vorgang).delete(VorgangLinkRel.DELETE);
	}

	getProposedWiederVorlageDatum(vorgang: VorgangResource): Observable<ProposedDate> {
		return this.resourceFactory.from(vorgang).get(VorgangLinkRel.PROPOSE_WIEDERVORLAGEDATUM);
	}
}
