import {Resource, ResourceUri} from '@ngxp/rest';
import {UserResource} from '@schir-int-client/user-shared';
import {Moment} from 'moment';

export enum VorgangTyp {
	NEUEINTRAGUNG = 'NEUEINTRAGUNG',
	AUSFLAGGUNG = 'AUSFLAGGUNG',
	FLAGGENWECHSEL = 'FLAGGENWECHSEL',
	NAMENSAENDERUNG = 'NAMENSAENDERUNG',
	ABTEILUNG_I_SONST = 'ABTEILUNG_I_SONST',
	EIGENTUMSVERAENDERUNG = 'EIGENTUMSVERAENDERUNG',
	INSOLVENZ = 'INSOLVENZ',
	HYPOTHEK = 'HYPOTHEK',
	LOESCHUNG = 'LOESCHUNG',
	SONSTIGES = 'SONSTIGES',
	ALLGEMEIN = 'ALLGEMEIN',
	REGISTERAUSDRUCK = 'REGISTERAUSDRUCK',

	//LFPR
	LFPR_NEUANMELDUNG = 'LFPR_NEUANMELDUNG',
	LFPR_EIGENTUMSWECHSEL = 'LFPR_EIGENTUMSWECHSEL',
	LFPR_NAMENSAENDERUNG = 'LFPR_NAMENSAENDERUNG',
	LFPR_ANGABENAENDERUNG = 'LFPR_ANGABENAENDERUNG',
	LFPR_WIDERSPRUCH = 'LFPR_WIDERSPRUCH',
	LFPR_ZWANGSVERSTEIGERUNGSVERMERK = 'LFPR_ZWANGSVERSTEIGERUNGSVERMERK',
	LFPR_INSOLVENZVERMERK = 'LFPR_INSOLVENZVERMERK',
	LFPR_INSOLVENZVERMERK_EROEFFNUNG = 'LFPR_INSOLVENZVERMERK_EROEFFNUNG',
	LFPR_RECHTSHAENGIGKEITSVERMERK = 'LFPR_RECHTSHAENGIGKEITSVERMERK',
	LFPR_LOESCHUNG_WIDERSPRUCH = 'LFPR_LOESCHUNG_WIDERSPRUCH',
	LFPR_LOESCHUNG_VERSTEIGERUNG_INSOLVENZVERMERK = 'LFPR_LOESCHUNG_VERSTEIGERUNG_INSOLVENZVERMERK',
	LFPR_LOESCHUNG_RECHTSHAENGIGKEITSVERMERK = 'LFPR_LOESCHUNG_RECHTSHAENGIGKEITSVERMERK',
	LFPR_LOESCHUNG_SCHUTZVERMERK = 'LFPR_LOESCHUNG_SCHUTZVERMERK',
	LFPR_LOESCHUNG_LUFTFAHRZEUG = 'LFPR_LOESCHUNG_LUFTFAHRZEUG',
	LFPR_REGISTERPFANDRECHT = 'LFPR_REGISTERPFANDRECHT',
	LFPR_GESAMTREGISTERPFANDRECHT = 'LFPR_GESAMTREGISTERPFANDRECHT',
	LFPR_REGISTERPFANDRECHT_HOECHSTBETRAG = 'LFPR_REGISTERPFANDRECHT_HOECHSTBETRAG',
	LFPR_REGISTERPFANDRECHT_ZWANGSVOLLSTRECKUNG = 'LFPR_REGISTERPFANDRECHT_ZWANGSVOLLSTRECKUNG',
	LFPR_VERAENDERUNG_REGISTERPFANDRECHTE = 'LFPR_VERAENDERUNG_REGISTERPFANDRECHTE',
	LFPR_LOESCHUNG_REGISTERPFANDRECHT = 'LFPR_LOESCHUNG_REGISTERPFANDRECHT',
	LFPR_LOESCHUNG_REGISTERPFANDRECHT_BLATT = 'LFPR_LOESCHUNG_REGISTERPFANDRECHT_BLATT',
	LFPR_SONSTIGES = 'LFPR_SONSTIGES'
}

export enum VorgangStatus {
	NEU = 'NEU',
	RECHTSPFLEGE = 'RECHTSPFLEGE',
	RUECKLAUF = 'RUECKLAUF',
	ABGESCHLOSSEN = 'ABGESCHLOSSEN',
	WIEDERVORLAGE = 'WIEDERVORLAGE',
}

export const folgeStatusList = [VorgangStatus.RECHTSPFLEGE, VorgangStatus.RUECKLAUF, VorgangStatus.ABGESCHLOSSEN, VorgangStatus.WIEDERVORLAGE];

export interface Vorgang extends CreateVorgang {
	laufendeNummerEinreichung: string;
	erstellungEinreichung: Date;
	anlageDatum: Date;
	typ: VorgangTyp;
	status: VorgangStatus;
	kennzeichenVerfahren: string;
	schiffsName: string;
	aktenzeichen: string;
	notiz: string;
	ruecklaufDatum: Date;
	wiedervorlageDatum: Date;
	rechtspfleger: UserResource;
	verfahrenId: string,
}

export interface CreateVorgang {
	posteingaenge: ResourceUri[],
	typ: VorgangTyp
}

export interface VorgangListResource extends Resource {}

export interface VorgangResource extends Vorgang, Resource {}

export interface ProposedDate {
	date: Date,
	multiple: boolean
}

export interface DatumsBereich {
	anfangsDatum: Moment;
	endDatum: Moment;
}
